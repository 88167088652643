<template>
  <div v-if="isLogin == true && this.isIos() == true">
    <div class="row h-100 w-100 p-0 m-0 text-left">
      <div
        class="navbar-fluid backBlueRec fixed-top navbar-expand-md pt-5 pb-3"
      >
        <a @click="eventGoBack"
          ><i class="fa-solid fa-circle-left d-inline ml-3"></i
        ></a>
        <p class="d-inline ml-3">{{ this.$route.name }}</p>
      </div>
    </div>
  </div>
  <div v-if="isLogin == false">
    <div
      v-if="this.isMobile() && isTopNavBar"
      class="row h-100 w-100 p-0 m-0 text-left"
    >
      <div
        class="navbar-fluid backBlueRec fixed-top navbar-expand-md"
        :class="this.isIos() ? 'pt-5 pb-3' : 'py-3'"
      >
        <a @click="eventGoBack"
          ><i class="fa-solid fa-circle-left d-inline ml-3"></i
        ></a>
        <p class="d-inline ml-3">{{ this.$route.name }}</p>
      </div>
    </div>
    <div v-else-if="this.isMobile() && isTopNavBar == false">
      <div class="row h-100 w-100 p-0 m-0">
        <nav
          class="navbar-fluid fixed-top navbar-expand-md"
          :class="this.isIos() ? 'navbar-new-bottom-ios' : 'navbar-new-bottom'"
        >
          <div
            class="navbar-expand"
            :class="this.isIos() ? 'mt-4' : ''"
            id="navbar2"
          >
            <ul class="nav justify-content-center h-100">
              <li class="nav-item float-left active">
                <a
                  class="navbar-brand"
                  href="#"
                  @click="eventNavMobileClick('WORKSHOP')"
                >
                  <img
                    src="@/assets/images/workshopService.png"
                    width="30"
                    height="30"
                    class="d-inline-block align-middle"
                    alt=""
                  />
                  <h6 class="d-inline-block align-middle titleNavbar">
                    WORKSHOP<br />SERVICE
                  </h6>
                </a>
              </li>
              <li class="nav-item align-middle">
                <a
                  class="navbar-brand"
                  href="#"
                  @click="eventNavMobileClick('HOMESERVICE')"
                >
                  <img
                    src="@/assets/images/homeService.png"
                    width="30"
                    height="30"
                    class="d-inline-block align-middle"
                    alt=""
                  />
                  <h6 class="d-inline-block align-middle titleNavbar">
                    HOME<br />SERVICE
                  </h6>
                </a>
              </li>

              <li class="nav-item float-right">
                <a
                  class="navbar-brand"
                  href="#"
                  @click="eventNavMobileClick('CATALOGUE')"
                >
                  <img
                    src="@/assets/images/catalogue.png"
                    width="30"
                    height="30"
                    class="d-inline-block align-middle"
                    alt=""
                  />
                  <h6 class="d-inline-block align-middle titleNavbar">
                    KATALOG<br />PRODUK
                  </h6>
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
    <div v-else class="fixed-top bg-white">
      <section id="topbar" class="d-none d-lg-block">
        <div class="clearfix w-100">
          <div class="contact-info float-left mx-2 px-2">
            <i class="fa-solid fa-envelope mx-2"></i
            ><a href="mailto:kartikasari.sosmed@gmail.com" target="_blank"
              >kartikasari.sosmed@gmail.com</a
            >
            <i class="fa-solid fa-phone mx-2"></i
            ><a href="tel:+62341-479000" target="_blank">0341 - 479000</a
            >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <i class="fa-brands fa-whatsapp mx-2"></i
            ><a href="https://wa.me/6285105069000" target="_blank"
              >0851-0506-9000</a
            >
          </div>
          <div class="social-links float-right mx-2 px-2">
            <a
              href="https://www.instagram.com/kartikasari.co.id/"
              class="instagram"
              target="_blank"
              ><i class="fa-brands fa-instagram"></i
            ></a>
            <a
              href="https://www.facebook.com/tytksm/"
              class="facebook"
              target="_blank"
              ><i class="fa-brands fa-facebook-f"></i
            ></a>
            <a
              href="https://www.youtube.com/channel/UCGeY3q00SQDRjPODt_MqkeQ"
              class="youtube"
              target="_blank"
              ><i class="fa-brands fa-youtube"></i
            ></a>
          </div>
        </div>
      </section>

      <div class="row h-100 w-100 my-3">
        <div class="col-xl-4 col-lg-4 col-12 d-flex justify-content-center">
          <div class="logo float-right">
            <a href="/">
              <img
                :src="this.urlSite + '/assets/img/logo.png'"
                alt="Kartika Sari Toyota"
                class="logo w-100"
              />
            </a>
          </div>
        </div>
        <div class="col-xl-8 col-lg-8 col-12 p-0">
          <nav
            class="nav-menu d-lg-block"
            :class="
              curScreen <= 991 ? 'd-flex justify-content-center' : 'float-right'
            "
          >
            <ul>
              <li :class="linkLogin == '/' ? 'active' : ''">
                <a href="/"><p>Home</p></a>
              </li>
              <li :class="linkLogin == '/#about' ? 'active' : ''">
                <a href="#about"><p>About Us</p></a>
              </li>
              <li
                :class="
                  linkLogin == '/News/Promo' ||
                  linkLogin == '/News/TipsTrick' ||
                  linkLogin == '/News/News%20And%20Event' ||
                  linkLogin.search('/NewsAndEvent') >= 0
                    ? 'active'
                    : ''
                "
                class="drop-down d-inline"
              >
                <a href="javascript:void(0)"
                  ><p class="d-inline">
                    News<i class="fa-solid fa-caret-down mx-1"></i></p
                ></a>
                <ul>
                  <li>
                    <a class="" href="/News/Promo"><p>Promo</p> </a>
                  </li>
                  <li>
                    <a class="" href="/News/TipsTrick"><p>Tips & Trick</p> </a>
                  </li>
                  <li>
                    <a class="" href="/News/News And Event"
                      ><p>News And Event</p>
                    </a>
                  </li>
                </ul>
              </li>
              <li
                class="drop-down d-inline"
                :class="
                  linkLogin == '/CATALOGUE' ||
                  linkLogin == '/TestDrive' ||
                  linkLogin == '/BROCHURE' ||
                  linkLogin == '/CreditSimulation' ||
                  linkLogin.search('/DetailCar') >= 0
                    ? 'active'
                    : ''
                "
              >
                <a href="javascript:void(0)"
                  ><p class="d-inline">
                    Sales<i class="fa-solid fa-caret-down mx-1"></i></p
                ></a>
                <ul>
                  <li>
                    <a href="/CATALOGUE"><p>Vehicles</p></a>
                  </li>
                  <!--
                  <li class="drop-down d-inline">
                    <a href="" ><p class="d-inline">Technology</p></a>
                    <ul>
                      <li>
                        <a href="" ><p>Judul</p></a>
                      </li> 
                    </ul>
                  </li>
                  <li><a href="#" class="belum"><p>Toyota Accessories</p></a></li>
                  -->
                  <li>
                    <a href="/TestDrive"><p>Test Drive</p></a>
                  </li>
                  <li>
                    <a href="/BROCHURE"><p>Brochure</p></a>
                  </li>
                  <li>
                    <a href="/CreditSimulation"><p>Kredit Simulasi</p></a>
                  </li>
                </ul>
              </li>
              <li
                class="drop-down d-inline"
                :class="
                  linkLogin == '/BookingService' ||
                  linkLogin == '/RoutinService' ||
                  linkLogin == '/Warranty'
                    ? 'active'
                    : ''
                "
              >
                <a href="javascript:void(0)"
                  ><p class="d-inline">
                    After Sales<i class="fa-solid fa-caret-down mx-1"></i></p
                ></a>
                <ul>
                  <li>
                    <a href="/BookingService"><p>Booking Service</p></a>
                  </li>
                  <li>
                    <a href="/RoutinService"><p>Routine service</p></a>
                  </li>
                  <li>
                    <a href="/Warranty"><p>Warranty</p></a>
                  </li>
                </ul>
              </li>
              <li
                class="drop-down d-inline"
                :class="
                  linkLogin == '/OurTeam' ||
                  linkLogin == '/SocialMedia' ||
                  linkLogin == '/PAP' ||
                  linkLogin == '/KonsultasiUnit' ||
                  linkLogin == '/KonsultasiService'
                    ? 'active'
                    : ''
                "
              >
                <a href="javascript:void(0)"
                  ><p class="d-inline">
                    Contact Us<i class="fa-solid fa-caret-down mx-1"></i></p
                ></a>
                <ul>
                  <li>
                    <a href="/OurTeam"><p>Contact Us</p></a>
                  </li>
                  <!-- 
                  <li><a href=""  class="belum" ><p>Career</p></a></li> -->
                  <li>
                    <a href="/SocialMedia"><p>Social Media</p></a>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.ksm.KartikaSari"
                      target="_blank"
                      ><p>KSM Mobile</p></a
                    >
                  </li>
                  <li>
                    <a href="/PAP"><p>Privacy & Policy</p></a>
                  </li>
                  <li>
                    <a href="/KonsultasiUnit"><p>Konsultasi Unit</p></a>
                  </li>
                  <li>
                    <a href="/KonsultasiService"><p>Konsultasi Service</p></a>
                  </li>
                </ul>
              </li>
              <li
                :class="
                  linkLogin == '/Shoping' ||
                  linkLogin.search('DetailProduct') >= 0
                    ? 'active'
                    : ''
                "
              >
                <a href="/Shoping" class="shopify">
                  <i class="icofont-shopify d-inline"></i>
                  <p class="d-inline">Shop</p>
                </a>
              </li>
              <li
                :class="
                  linkLogin == '/Career' || linkLogin.search('Career') >= 0
                    ? 'active'
                    : ''
                "
              >
                <a href="/Career" class="shopify">
                  <i class="icofont-shopify d-inline"></i>
                  <p class="d-inline">Career</p>
                </a>
              </li>
              <li>
                <a
                  href="javascript:void(0)"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i class="fa-solid fa-user"></i>
                  <span v-if="UserNameLogin == 'Login'"> Login</span>
                </a>
                <ul v-if="UserNameLogin != 'Login'" class="dropdown-menu">
                  <li>
                    <div class="row p-3" style="min-width: 300px">
                      <div
                        class="col-12 w-100 d-flex justify-content-center my-1"
                      >
                        <img
                          src="../src/assets/images/profile.png"
                          width="50"
                        />
                      </div>
                      <div
                        class="col-12 w-100 d-flex justify-content-center my-1"
                      >
                        <a href="/ORDERSTATUS" class="removeBlueLink">
                          {{ UserNameLogin }}
                        </a>
                      </div>
                      <div class="col-12 w-100 my-1">
                        <a
                          class="d-inline btn btn-sm float-left"
                          href="/Profile"
                        >
                          <i class="fa-solid fa-gear"></i>Setting
                        </a>
                        <a
                          @click="Logout"
                          class="d-inline btn btn-sm float-right"
                        >
                          <i class="fa-solid fa-arrow-right-from-bracket"></i
                          >Logout
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul v-else id="login-dp" class="dropdown-menu">
                  <li>
                    <div class="card shadow">
                      <div class="row p-3" style="min-width: 300px">
                        <div class="col-12 mt-1">
                          <form class="mx-1" method="post" action="#">
                            <div class="form-group mb-2">
                              <input
                                type="text"
                                class="form-control form-control-sm text-center roundedAll"
                                v-model="userName"
                                id="userID"
                                name="userID"
                                placeholder="User ID/ Phone"
                              />
                            </div>
                            <div class="form-group mb-2">
                              <input
                                type="password"
                                class="form-control form-control-sm text-center roundedAll"
                                v-model="password"
                                id="password"
                                name="password"
                                placeholder="Password"
                              />
                            </div>
                            <!--
                          <div class="form-group">
                            <div class="form-check mb-1 ">
                              <input type="checkbox" class="form-check-input" name="remember" id="remember">
                              <label class="form-check-label float-right " for="#remember">
                                <small>Remember Me</small>
                              </label>
                            </div>
                          </div>
                          -->
                            <input
                              type="button"
                              id="btnLogin"
                              @click="EventLogin"
                              class="btn btn-ksm btn-block btn-sm py-1"
                              value="Login"
                            />
                            <div class="mt-2">
                              <a
                                href="/RegisterUsers"
                                class="bgn btn-sm float-left py-1 d-inline text-dark"
                                ><small>Register</small></a
                              >
                              <a
                                href="#"
                                class="btn btn-sm float-right py-1 d-inline text-dark"
                                id="btnForgotPassword"
                                data-toggle="modal"
                                data-target="#modalForgotPass"
                                ><small>Forgot Password?</small></a
                              >
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="this.isMobile() == false"
    class="col-12"
    style="min-height: 10px"
  ></div>

  <div v-if="this.isMobile() == true && !isLoading" class="mt-3">
    <facebook-loader :speed="1" :animate="true"></facebook-loader>
    <Code-loader :speed="1" :animate="true"></Code-loader>
    <List-loader :speed="1" :animate="true"></List-loader>
    <BulletList-loader :speed="1" :animate="true"></BulletList-loader>
    <content-loader :speed="1" :animate="true"> </content-loader>
  </div>
  <div
    v-else
    :class="
      this.isIos() && isTopNavBar == false
        ? 'w-100 mb-0 mx-0 p-0 margin-top-not-ios'
        : 'w-100 m-0 p-0 margin-top-ios'
    "
  >
    <router-view />
  </div>

  <div v-if="isLogin == false">
    <div v-if="this.isMobile()">
      <div v-if="isBottomNavBar" class="row h-100 w-100 p-0 mb-0">
        <nav class="navbar-fluid fixed-bottom navbar-expand-md navbar-new-top">
          <div class="navbar-expand" id="navbar2">
            <div class="row d-flex justify-content-between w-100 mx-0 mt-1 p-0">
              <div v-if="isUserLogin == true" class="col-2 w-100 m-0 p-0">
                <a class="navbar-brand w-100" href="/ORDERSTATUS">
                  <div class="d-flex justify-content-center">
                    <img
                      v-if="linkLogin == '/ORDERSTATUS'"
                      src="@/assets/images/ic_document_red.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/ic_document.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                  </div>
                  <p
                    :class="
                      linkLogin == '/ORDERSTATUS'
                        ? 'align-middle w-100 text-center titleBottomBarBlue'
                        : 'align-middle w-100 text-center titleBottomBar '
                    "
                  >
                    Order Status
                  </p>
                </a>
              </div>
              <div v-if="isUserLogin == true" class="col-2 w-100 m-0 p-0">
                <a class="navbar-brand w-100" href="/SHOPING">
                  <div class="d-flex justify-content-center">
                    <img
                      v-if="
                        linkLogin == '/SHOPING' ||
                        linkLogin.search('DetailCar') >= 0
                      "
                      src="@/assets/images/icon_bag_red.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/icon_bag_white.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                  </div>
                  <p
                    :class="
                      linkLogin == '/SHOPING'
                        ? 'align-middle w-100 text-center titleBottomBarBlue'
                        : 'align-middle w-100 text-center titleBottomBar'
                    "
                  >
                    Shoping
                  </p>
                </a>
              </div>
              <div v-else class="col-3 w-100 m-0 p-0">
                <a class="navbar-brand w-100" href="/FACILITIES">
                  <div class="d-flex justify-content-center">
                    <img
                      v-if="linkLogin == '/FACILITIES'"
                      src="@/assets/images/ic_facilities_red_alt.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/ic_facilities_grey_alt.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                  </div>
                  <p
                    :class="
                      linkLogin == '/FACILITIES'
                        ? 'align-middle w-100 text-center titleBottomBarBlue'
                        : 'align-middle w-100 text-center titleBottomBar'
                    "
                  >
                    Facilities
                  </p>
                </a>
              </div>
              <div
                :class="isUserLogin == true ? 'col-2' : 'col-3'"
                class="w-100 m-0 p-0"
              >
                <a class="navbar-brand w-100" href="/">
                  <div class="d-flex justify-content-center">
                    <img
                      v-if="linkLogin == '/'"
                      src="@/assets/images/ic_home_red.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/ic_home_alt.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                  </div>
                  <p
                    :class="
                      linkLogin == '/'
                        ? 'align-middle w-100 text-center titleBottomBarBlue'
                        : 'align-middle w-100 text-center titleBottomBar'
                    "
                  >
                    Home
                  </p>
                </a>
              </div>
              <div
                :class="
                  isUserLogin == true
                    ? 'col-2 w-100 m-0 p-0'
                    : 'col-3 w-100 m-0 p-0'
                "
              >
                <a class="navbar-brand w-100" href="/BRANCH">
                  <div class="d-flex justify-content-center">
                    <img
                      v-if="linkLogin == '/BRANCH'"
                      src="@/assets/images/ic_location_red.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/ic_location.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                  </div>
                  <p
                    :class="
                      linkLogin == '/BRANCH'
                        ? 'align-middle w-100 text-center titleBottomBarBlue'
                        : 'align-middle w-100 text-center titleBottomBar'
                    "
                  >
                    Our Branch
                  </p>
                </a>
              </div>
              <div v-if="isUserLogin == true" class="col-2 w-100 m-0 p-0">
                <a class="navbar-brand w-100" href="/SETTING">
                  <div class="d-flex justify-content-center">
                    <img
                      v-if="
                        linkLogin == '/SETTING' ||
                        linkLogin == '/ABOUT' ||
                        linkLogin == '/TOS' ||
                        linkLogin == '/PAP' ||
                        linkLogin == '/Profile' ||
                        linkLogin == '/CreditSimulation' ||
                        linkLogin == '/UNITS' ||
                        linkLogin == '/HISTORYNOTIF'
                      "
                      src="@/assets/images/ic_setting_red.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                    <img
                      v-else
                      src="@/assets/images/ic_setting.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                  </div>
                  <p
                    :class="
                      linkLogin == '/SETTING'
                        ? 'align-middle w-100 text-center titleBottomBarBlue'
                        : 'align-middle w-100 text-center titleBottomBar'
                    "
                  >
                    Setting
                  </p>
                </a>
              </div>
              <div class="col-3 w-100 m-0 p-0">
                <a
                  class="navbar-brand w-100"
                  href="javascript:void(0)"
                  @click="showModalQr"
                >
                  <div class="d-flex justify-content-center">
                    <img
                      src="@/assets/images/qrwhite.png"
                      width="25"
                      height="25"
                      class="align-middle"
                      alt=""
                    />
                  </div>
                  <p class="align-middle w-100 text-center titleBottomBar">
                    Register
                  </p>
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div v-else>
      <footer class="">
        <div class="footer-top mt-3">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 footer-newsletter mt-3">
                <h4 class="text-left">Business hours</h4>
                <table>
                  <tr>
                    <th>Sunday</th>
                    <td>&nbsp;:&nbsp;9:00 Am – 3:00 Pm</td>
                  </tr>
                  <tr>
                    <th>Monday</th>
                    <td>&nbsp;:&nbsp;8:00 Am – 5:00 Pm</td>
                  </tr>
                  <tr>
                    <th>Tuesday</th>
                    <td>&nbsp;:&nbsp;8:00 Am – 5:00 Pm</td>
                  </tr>
                  <tr>
                    <th>Wednesday</th>
                    <td>&nbsp;:&nbsp;8:00 Am – 5:00 Pm</td>
                  </tr>
                  <tr>
                    <th>Thursday</th>
                    <td>&nbsp;:&nbsp;8:00 Am – 5:00 Pm</td>
                  </tr>
                  <tr>
                    <th title="Special Night Service">Friday</th>
                    <td>&nbsp;:&nbsp;8:00 Am – 9:00 Pm</td>
                  </tr>
                  <tr>
                    <th>Saturday</th>
                    <td>&nbsp;:&nbsp;8:00 Am – 3:00 Pm</td>
                  </tr>
                </table>
              </div>

              <div class="col-lg-3 col-md-6 footer-links mt-3">
                <h4 class="text-left">Usefull Link</h4>
                <ul class="text-left">
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="/">Home</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="/Promo">Promo</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="/TipsAndTrick"
                      >Tips & Trick</a
                    >
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="/NewsAndEvent"
                      >News & Event</a
                    >
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="/SocialMedia"
                      >Social Media</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-lg-3 col-md-6 footer-links mt-3">
                <h4 class="text-left">Our Services</h4>
                <ul class="text-left">
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="Kartikasari/Vehicle"
                      >Vehicles</a
                    >
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="#">Toyota Accessories</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="Kartikasari/KonsultasiUnit"
                      >Test Drive</a
                    >
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="Kartikasari/BookingService"
                      >Booking Service</a
                    >
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="Kartikasari/RoutinService"
                      >Routine Service</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-lg-2 col-md-6 footer-links mt-3">
                <h4 class="text-left">Technology</h4>
                <ul class="text-left">
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="#">TNGA</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="#">Hybrid</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a class="footerColorLink" href="#"
                      >Electric Vehicle System</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="container pb-3">
          <div class="col-md-12 col-sm-12 d-flex justify-content-center">
            <a class="text-white" href="/PAP">Privacy And Policy</a>
          </div>
          <div class="copyright d-flex justify-content-center">
            &copy; Copyright <strong><span> Kartika Sari Toyota</span></strong
            >. All Rights Reserved
          </div>
        </div>
      </footer>
    </div>
  </div>

  <!-- Modal Close This App -->
  <div
    class="modal fade"
    id="modalClose"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content roundedCarousel">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <label class="d-flex justify-content-center"
                >Yakin Mau Keluar?</label
              >
            </div>
            <div class="col-6">
              <button
                @click="closeThisApp"
                class="btn btn-sm btn-ksm-red btn-block"
              >
                Ya, Keluar Sekarang
              </button>
            </div>
            <div class="col-6">
              <button
                @click="closeModalApp"
                class="btn btn-sm btn-block btn-ksm"
              >
                Tidak, Nanti Saja
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- floating VOC -->
  <a
    v-if="
      statusVoc == 'show' &&
      isMobile() == true &&
      isLogin == false &&
      isUserLogin == true &&
      hideVoc == false &&
      isFormVoc == false
    "
    href="javascript:;"
    class="float-mobile-voc"
    @click="gotoVoc"
  >
    <img src="@/assets/images/icon-voc.png" width="100" height="100" />
  </a>
  <!-- floating close VOC -->
  <a
    v-if="
      statusVoc == 'show' &&
      isMobile() == true &&
      isLogin == false &&
      isUserLogin == true &&
      hideVoc == false &&
      isFormVoc == false
    "
    href="javascript:;"
    class="float-mobile-voc-close"
    @click="setHideVoc"
  >
    <i class="fa-solid fa-circle-xmark text-danger"></i>
  </a>

  <!-- floating chat -->
  <a
    v-if="statusChat == 'default' && isMobile() == false && isLogin == false"
    href="javascript:;"
    class="float"
    @click="showChat"
  >
    <i class="fa-solid fa-comment-dots my-float"></i>
  </a>
  <a
    v-if="statusChat == 'default' && isMobile() == true && isLogin == false"
    href="javascript:;"
    class="float-mobile"
    @click="showChat"
  >
    <i class="fa-solid fa-comment-dots my-float-mobile"></i>
  </a>
  <!-- float form minimize -->
  <span
    v-if="statusChat == 'minimize' && isMobile() == false && isLogin == false"
    class="floatFormChatMinimize"
  >
    <div class="card w-100 m-0 p-0 h-100">
      <div class="card-header mx-1 px-1 my-0 py-0">
        <div class="row w-100 m-0 p-0">
          <div class="col-9 w-100 m-0 p-0">
            <p v-if="UserNameLogin">{{ UserNameLogin }}</p>
            <p v-else>Anonimous</p>
          </div>
          <div class="col-3 w-100 m-0 p-0">
            <span v-if="jmlChatNoRep.jml > 0" class="badge badge-danger m-1">{{
              jmlChatNoRep.jml
            }}</span>
            <a @click="closeChat"
              ><i class="fa-solid fa-xmark m-1 float-right"></i
            ></a>
            <a @click="showChat"
              ><i class="fa-solid fa-angle-up m-1 float-right"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </span>
  <span
    v-if="statusChat == 'minimize' && isMobile() == true && isLogin == false"
    class="floatFormChatMinimize-mobile"
  >
    <div class="card w-100 m-0 p-0 h-100">
      <div class="card-header mx-1 px-1 my-0 py-0">
        <div class="row w-100 m-0 p-0">
          <div class="col-9 w-100 m-0 p-0">
            <p v-if="UserNameLogin" style="font-size: 12px">
              {{ UserNameLogin }}
            </p>
            <p v-else>Anonimous</p>
          </div>
          <div
            class="col-3 w-100 m-0 p-0 float-right d-flex justify-content-right"
          >
            <p v-if="jmlChatNoRep.jml > 0" class="badge badge-danger">
              <small style="font-size: 12px">{{ jmlChatNoRep.jml }}</small>
            </p>
            <a
              @click="showChat"
              class="m-0 p-0 w-100 d-flex justify-content-center"
            >
              <i
                style="width: 8px"
                class="fa-solid fa-angle-up m-1 float-right"
              ></i>
            </a>
            <a
              @click="closeChat"
              class="m-0 p-0 w-100 d-flex justify-content-center"
            >
              <i
                style="width: 8px"
                class="fa-solid fa-xmark m-1 float-right"
              ></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </span>

  <!-- float form chat -->
  <span
    v-if="statusChat == 'open' && isMobile() == false && isLogin == false"
    class="floatFormChat"
  >
    <div class="card w-100 m-0 p-0 h-100">
      <div class="card-header mx-1 px-1 my-0 py-0">
        <div class="row w-100 m-0 p-0">
          <div class="col-10 w-100 m-0 p-0">
            <p v-if="UserNameLogin">{{ UserNameLogin }}</p>
            <p v-else>Anonimous</p>
          </div>
          <div class="col-2 w-100 m-0 p-0">
            <a @click="minimizeChat"
              ><i class="fa-solid fa-angle-down d-inline m-1"></i
            ></a>
            <a @click="closeChat"><i class="fa-solid fa-xmark m-1"></i></a>
          </div>
        </div>
      </div>
      <div
        class="card-body overflow-auto w-100 m-0 p-0"
        ref="scrollChat"
        id="scrollChat"
      >
        <span v-for="(chat, index) in resChat" :key="index">
          <!-- client side -->
          <div v-if="chat.IsAdmin == '1'" class="row w-100 m-0 p-0">
            <div class="col-2 d-flex justify-content-center">
              <img
                src="@/assets/images/icon_tanya_kartika.png"
                width="35"
                height="35"
              />
            </div>
            <div class="col-10 w-100 m-0 p-0">
              <div class="w-100">
                <p class="bg-light shadow rounded align-middle m-1 p-1">
                  {{ chat.Chat }}
                  <br /><small style="font-size: 10px">{{
                    chat.TglEntry
                  }}</small>
                </p>
              </div>
            </div>
          </div>
          <!-- admin side -->
          <div v-if="chat.IsAdmin == '0'" class="row w-100 m-0 p-0">
            <div class="col-10 w-100 m-0 p-0">
              <div class="w-100">
                <p class="bg-chat-ksm shadow rounded align-middle m-1 p-1">
                  {{ chat.Chat }}
                  <br /><small style="font-size: 10px">{{
                    chat.TglEntry
                  }}</small>
                </p>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-center">
              <img src="@/assets/images/profile.png" width="35" height="35" />
            </div>
          </div>
        </span>
      </div>
      <div class="card-footer w-100 m-0 p-0">
        <div class="row w-100 m-0 p-0">
          <div class="col-10 w-100 m-0 p-0">
            <div class="form-group m-0 p-0 w-100">
              <input
                v-model="valMessage"
                class="form-control form-control-sm"
                id="valueChat"
                type="text"
                placeholder="Type Message..."
                v-on:keyup.enter="sendMsg"
              />
            </div>
          </div>
          <div
            class="col-2 w-100 m-0 p-0 d-flex justify-content-center"
            @click="sendMsg"
          >
            <i class="fa-solid fa-paper-plane btn btn-block"></i>
          </div>
        </div>
      </div>
    </div>
  </span>
  <span
    v-if="statusChat == 'open' && isMobile() == true && isLogin == false"
    class="floatFormChat-mobile"
  >
    <div class="card w-100 m-0 p-0 h-100">
      <div class="card-header mx-1 px-1 my-0 py-0">
        <div class="row w-100 m-0 p-0">
          <div class="col-9 w-100 m-0 p-0">
            <p v-if="UserNameLogin" style="font-size: 12px">
              {{ UserNameLogin }}
            </p>
            <p v-else>Anonimous</p>
          </div>
          <div
            class="col-3 w-100 m-0 p-0 float-right d-flex justify-content-right"
          >
            <a
              @click="minimizeChat"
              class="m-0 p-0 w-100 d-flex justify-content-center"
            >
              <i
                style="width: 8px"
                class="fa-solid fa-angle-down d-inline m-1"
              ></i>
            </a>
            <a
              @click="closeChat"
              class="m-0 p-0 w-100 d-flex justify-content-center"
            >
              <i style="width: 8px" class="fa-solid fa-xmark m-1"></i>
            </a>
          </div>
        </div>
      </div>
      <div
        class="card-body overflow-auto w-100 m-0 p-0"
        ref="scrollChat"
        id="scrollChat"
      >
        <span v-for="(chat, index) in resChat" :key="index">
          <!-- client side -->
          <div v-if="chat.IsAdmin == '1'" class="row w-100 m-0 p-0">
            <div class="col-2 d-flex justify-content-center">
              <img
                src="@/assets/images/icon_tanya_kartika.png"
                width="20"
                height="20"
              />
            </div>
            <div class="col-10 w-100 m-0 p-0">
              <div class="w-100">
                <p
                  class="bg-light shadow rounded align-middle m-1 p-1 text-left"
                  style="font-size: 12px"
                >
                  {{ chat.Chat }}
                  <br /><small style="font-size: 8px">{{
                    chat.TglEntry
                  }}</small>
                </p>
              </div>
            </div>
          </div>
          <!-- admin side -->
          <div v-if="chat.IsAdmin == '0'" class="row w-100 m-0 p-0">
            <div class="col-10 w-100 m-0 p-0">
              <div class="w-100">
                <p
                  class="bg-chat-ksm shadow rounded align-middle m-1 p-1 text-left"
                  style="font-size: 12px"
                >
                  {{ chat.Chat }}
                  <br /><small style="font-size: 8px">{{
                    chat.TglEntry
                  }}</small>
                </p>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-center">
              <img src="@/assets/images/profile.png" width="20" height="20" />
            </div>
          </div>
        </span>
      </div>
      <div class="card-footer w-100 m-0 p-0">
        <div class="row w-100 m-0 p-0">
          <div class="col-10 w-100 m-0 p-0">
            <div class="form-group m-0 p-0 w-100">
              <input
                v-model="valMessage"
                class="form-control form-control-sm"
                id="valueChat"
                type="text"
                placeholder="Type Message..."
                v-on:keyup.enter="sendMsg"
              />
            </div>
          </div>
          <div
            class="col-2 w-100 m-0 p-0 d-flex justify-content-center"
            @click="sendMsg"
          >
            <i class="fa-solid fa-paper-plane btn btn-block"></i>
          </div>
        </div>
      </div>
    </div>
  </span>

  <!-- modal qr -->
  <div
    class="modal fade"
    id="modalQr"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Scan Atau Ambil QR Kode
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-6">
              <qrcode-capture
                @detect="onDetect"
                :capture="user"
                class="w-100 pb-2"
              >
              </qrcode-capture>
            </div>
            <div class="col-6">
              <a href="/RegisterUsers" class="btn btn-sm btn-ksm btn-block"
                >Lanjut Ke Register</a
              >
            </div>
            <div v-if="this.isMobile()" class="col-12">
              <div class="card rounded border shadow" style="min-height: 400px">
                <qrcode-stream @detect="onDetect"></qrcode-stream><br />
              </div>
            </div>
            <div v-else class="col-12">
              <label class="text-center text-info"
                >Device Tidak Support Untuk Scan</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal Forgot Password -->
  <div
    v-if="!isMobile()"
    class="modal fade"
    id="modalForgotPass"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content roundedCarousel">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <label class="d-flex justify-content-center"
                ><strong>FORGOT PASSWORD</strong></label
              >
            </div>
            <div class="col-12">
              <div class="form-group text-left">
                <label for="emailForgotPass">Your Email</label>
                <input
                  type="email"
                  id="emailForgotPass"
                  class="form-control form-control-sm roundedAll"
                  v-model="emailForgotPassword"
                  placeholder="admin@kartikasari.co.id"
                />
              </div>
              <button
                class="btn btn-block btn-sm btn-ksm text-white"
                @click="eventSendNotif"
              >
                Send My Code
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal PAP -->
  <div
    class="modal fade"
    id="modalPap"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered my-2" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <strong>Privacy And Policy</strong>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="modal-body"
          style="height: 65vh; overflow-y: auto; overflow-x: hidden"
        >
          <div class="row">
            <div class="col-12" style="margin-top: -75px">
              <p v-html="valPAP"></p>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="text-center w-100">
            <div class="form-check mb-3">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexCheckDefault"
                @click="egreeChecked"
              />
              <label
                class="form-check-label text-danger"
                for="flexCheckDefault"
              >
                <small
                  >Saya setuju dengan Kebijakan Privasi dan Syarat &
                  Ketentuan</small
                >
              </label>
            </div>
            <button
              id="btnPap"
              class="btn btn-info btn-block disabled"
              @click="submitPap"
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.margin-top-ios {
  margin-top: 95px;
}
.margin-top-not-ios {
  margin-top: 85px;
}
.float {
  z-index: 999;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
.float-mobile {
  z-index: 999;
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 70px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 20px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
.float-mobile-voc {
  z-index: 999;
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 155px;
  right: 50px;
}
.float-mobile-voc-close {
  z-index: 999;
  position: fixed;
  width: 20px;
  height: 20px;
  bottom: 165px;
  right: 55px;
}
.floatFormChat {
  z-index: 999;
  position: fixed;
  width: 300px;
  height: 500px;
  bottom: 10px;
  right: 10px;
}
.floatFormChat-mobile {
  z-index: 999;
  position: fixed;
  width: 200px;
  height: 350px;
  bottom: 75px;
  right: 10px;
}
.floatFormChatMinimize {
  z-index: 999;
  position: fixed;
  width: 300px;
  height: 50px;
  bottom: 10px;
  right: 10px;
}
.floatFormChatMinimize-mobile {
  z-index: 999;
  position: fixed;
  width: 200px;
  height: 50px;
  bottom: 75px;
  right: 10px;
}

.my-float {
  margin-top: 22px;
}
.my-float-mobile {
  margin-top: 13px;
}
.bg-chat-ksm {
  background: #f7d2d2;
}
</style>

<script>
import $ from "jquery";
import firebase from "firebase/app";
import "firebase/messaging";
import { Capacitor, Plugins } from "@capacitor/core";
import {
  ContentLoader,
  FacebookLoader,
  CodeLoader,
  ListLoader,
  BulletListLoader,
} from "vue-content-loader";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

const { App } = Plugins;

export default {
  components: {
    ContentLoader,
    FacebookLoader,
    CodeLoader,
    ListLoader,
    BulletListLoader,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  methods: {
    async getReferred() {
      const resultData = await this.FuncGetSecond(
        "https://admin.kartikasari.co.id/HttpApi/GetReferred"
      );
      this.employes = resultData.data;
    },
    async GetUpdateApi() {
      var getPlatform = Capacitor.getPlatform();

      if (getPlatform != "web") {
        if (
          localStorage.isCheckUpdated == undefined ||
          localStorage.isCheckUpdated == "false"
        ) {
          const getData = await this.getApkVersionApi(getPlatform);
          console.log(
            "check version ",
            getData.data[0].Version,
            this.myVersion,
            localStorage.isCheckUpdated
          );
          if (getData.data[0].Version != this.myVersion) {
            const text = "Versi Baru Sudah Tersedia, Update Sekarang!!!";
            if (confirm(text) == true) {
              localStorage.isCheckUpdated = "true";
              window.open(getData.data[0].Link, "_blank");
            }
          }
        } else {
          console.log("sudah check update");
        }
        localStorage.isCheckUpdated = "true";
      }
    },
    showModalQr() {
      $("#modalQr").modal("show");
    },
    onError(err) {
      if (this.isMobile()) {
        alert(err.message);
      }
    },
    onDetect(detectedCodes) {
      //console.log(detectedCodes[0].rawValue);
      var isFound = this.employes.filter(
        (x) =>
          x.NamaLengkap.replace(" ", "") ==
          detectedCodes[0].rawValue.replace(" ", "")
      );
      if (isFound.length > 0) {
        window.location.href = "/RegisterUsers/" + detectedCodes[0].rawValue;
      } else {
        alert("Nama Sales Tidak Ditemukan");
        window.location.href = "/RegisterUsers";
      }
      $("#modalQr").modal("hide");
    },
    gotoVoc() {
      window.location.href = "/Voc";
    },
    setHideVoc() {
      if (this.hideVoc == true) {
        this.hideVoc = false;
      } else {
        this.hideVoc = true;
      }
    },
    async eventSendNotif() {
      if (this.emailForgotPassword) {
        var form_data = {};
        form_data["emailCust"] = this.emailForgotPassword;
        const resultData = await this.FuncPostSecond(
          this.urlSite + "/HttpApi/changePassword",
          form_data
        );
        console.log("result login", resultData.data.result);
        if (resultData.data.result.search("Email Sent") >= 0) {
          alert("Periksa Link Ganti Password Di Email Anda");
          $("#modalForgotPass").modal("hide");
        } else {
          alert("Failed, Check Koneksi Anda!!!");
        }
      } else {
        alert("Isi Email Anda");
      }
    },
    closeThisApp() {
      localStorage.IsClose = "true";
      localStorage.isCheckUpdated = "false";
      localStorage.firstLoadApk = "false";
      navigator["app"].exitApp();
    },
    closeModalApp() {
      $("#modalClose").modal("hide");
    },
    /*
          isMobile(){
              if(Capacitor.getPlatform() === 'android' || screen.width <= 760)
              {
                return true;
              }
              else
              {
                return false;
              }
            },
            */
    async InsertMsg() {
      var form_data = {};
      form_data["KodePelanggan"] = localStorage.CalonKode.replace(
        "00001/C",
        "KSM"
      );

      form_data["Chat"] = this.valMessage;
      const resultData = await this.FuncPostSecond(
        this.urlSite + "/HttpApi/SendChat",
        form_data
      );
      return resultData;
      //console.log("result insert", resultData.data.search("Success"));
    },
    async EventLogin() {
      var form_data = {};
      form_data["userID"] = this.userName;
      form_data["password"] = this.password;
      const resultData = await this.FuncPostSecond(
        this.urlSite + "/HttpApi/LoginUser",
        form_data
      );
      if (!resultData.data) {
        alert("User atau Password Salah");
      } else {
        this.dataUser = resultData.data;
        localStorage.Kode = this.dataUser.Kode;
        localStorage.Email = this.dataUser.Email;
        localStorage.Telp = this.dataUser.Telp;
        localStorage.Alamat = this.dataUser.Alamat;
        localStorage.Kota = this.dataUser.Kota;
        localStorage.TglLahirOwner = this.dataUser.TglLahirOwner;
        this.UserNameLogin = this.userName;
        window.location.href = "/";
      }
    },
    async Logout() {
      const dataReturn = this.funcLogoutSecond();
      if (dataReturn) {
        window.location.href = "/";
      }
    },
    eventNavMobileClick: function (jenis) {
      if (this.UserNameLogin == "Login" && jenis != "CATALOGUE") {
        window.location.href = "/LoginUser";
      } else {
        window.location.href = "/" + jenis;
      }
    },
    eventGoBack() {
      if (localStorage.isFormServiceOpen == "true") {
        localStorage.isFormServiceOpen = "false";
        window.location.reload();
      } else {
        this.$router.go(-1);
      }
    },
    clearStorage() {
      //console.log("before unload");
      //localStorage.IsClose = "true";
      //this.ClearLocalStorage();
    },

    showChat() {
      if (localStorage.Kode) {
        this.statusChat = "open";
        $("#valueChat").focus();
      } else {
        window.location.href = "/LoginUser";
      }
    },
    minimizeChat() {
      this.statusChat = "minimize";
    },
    closeChat() {
      this.statusChat = "default";
    },
    async getChatCust() {
      if (localStorage.Kode) {
        var form_data = {};
        form_data["codeCust"] = localStorage.CalonKode.replace(
          "00001/C",
          "KSM"
        ); //semua login di ganti ke calon cust
        //console.log("codeCust", form_data);
        const resultData = await this.FuncPostSecond(
          this.urlSite + "/HttpApi/GetChatCust",
          form_data
        );
        this.resChat = resultData.data.results;
        this.jmlChatNoRep = resultData.data.jmlChat;
        //console.log(localStorage.Kode, localStorage.CalonKode);
      }
    },
    async sendMsg() {
      var d = new Date();
      var mm = String(d.getMonth() + 1).padStart(2, "0");
      var dd = String(d.getDate()).padStart(2, "0");
      var yy = d.getFullYear();
      var myDateString =
        yy +
        "-" +
        mm +
        "-" +
        dd +
        " " +
        new Date().toLocaleTimeString("IDN", { hour12: false }) +
        ".000";

      var getMsg = this.valMessage;
      if (getMsg != "") {
        var retInsert = await this.InsertMsg();

        if (retInsert.data.search("Success") >= 0) {
          this.resChat.push({
            Chat: getMsg,
            Email: localStorage.Email,
            IsAdmin: "0",
            StClose: "0",
            Telp: localStorage.Telp,
            TglEntry: myDateString,
            UserName: "",
          });
        } else {
          alert("Failed Send Message, Please Check Your Connection!!!");
        }

        this.valMessage = "";
      }
      $("#valueChat").focus();
    },
    async GetRealTimeChat() {
      if (localStorage.Kode) {
        //console.log(localStorage);
        var form_data = {};
        form_data["codeCust"] = localStorage.CalonKode.replace(
          "00001/C",
          "KSM"
        );
        const resultData = await this.FuncPostSecond(
          this.urlSite + "/HttpApi/GetChatCust",
          form_data
        );
        var curChat = resultData.data.results;
        /*
              if(resultData.data.results.length > this.resChat.length)
              {
                console.log("ada chat baru nih", resultData.data.results[this.data.results.length-1]);
              }
              */
        /*
              console.log("sebelum", this.resChat);
              //simulasi jika ada chat baru dari admin
              curChat.push({
                  Chat: "chat baru",
                  Email: localStorage.Email,
                  IsAdmin: "1",
                  StClose: "0",
                  Telp: localStorage.Telp,
                  TglEntry: "tanggal",
                  UserName: "",
              });
              */

        if (curChat.length > this.resChat.length) {
          curChat.splice(0, this.resChat.length);
          Array.prototype.push.apply(this.resChat, curChat);
          //console.log("after merger", this.resChat);
        }
      }
    },
    receiveMessage() {
      try {
        firebase.messaging().onMessage((payload) => {
          console.log("payload ", payload);
          this.showToast(payload);
        });
      } catch (e) {
        console.log(e);
      }
    },

    showToast(payload) {
      this.$toast.open({
        message: payload.notification.body,
        position: "top-right",
        type: "info",
        onClick: () => {
          window.location = payload.notification.click_action;
        },
      });
    },
    async getPaP() {
      var form_data = {};
      const resultData = await this.FuncGetSecond(
        this.urlSite + "/HttpApi/GetPaP",
        form_data
      );
      var getRes = resultData.data.results;
      this.valPAP = getRes.Detail.replaceAll(
        "0px 150px 0px 150px",
        "0px 0px 0px 0px"
      ).replaceAll("<table", "<table style='width: 100%;'");

      if (
        this.isUserLogin == true &&
        this.isAgree == false &&
        (localStorage.firstLoadApk == undefined ||
          localStorage.firstLoadApk == "false")
      ) {
        $("#modalPap").modal("show");
        localStorage.firstLoadApk = "true";
      }
    },
    egreeChecked() {
      var statusBtn = $("#btnPap").attr("class");
      if (statusBtn.search("disabled") >= 0) {
        $("#btnPap").removeClass("disabled");
      } else {
        $("#btnPap").addClass("disabled");
      }
    },
    async submitPap() {
      var form_data = {};
      form_data["Kode"] = localStorage.Kode;
      form_data["Email"] = localStorage.Email;
      form_data["Telp"] = localStorage.Telp;
      const resultData = await this.FuncPostSecond(
        this.urlSite + "/Api/AgreePap",
        form_data
      );
      if (resultData.data.status.search("Success") >= 0) {
        alert(resultData.data.message);
        $("#modalPap").modal("hide");
      } else {
        alert("Failed, " + resultData.data.message);
      }
    },
    async checkingAgree() {
      var form_data = {};
      form_data["Kode"] = localStorage.Kode;
      form_data["Email"] = localStorage.Email;
      form_data["Telp"] = localStorage.Telp;
      const resultData = await this.FuncPostSecond(
        this.urlSite + "/Api/IsAgree",
        form_data
      );
      if (resultData.data.status.search("Success") >= 0) {
        if (resultData.data.message == true) {
          this.isAgree = true;
        } else {
          this.isAgree = false;
        }
      } else {
        alert("Failed, Server Error");
      }
    },
  },

  data() {
    return {
      isLogin: false,
      userName: "",
      password: "",
      UserNameLogin: "Login",
      isTopNavBar: false,
      isBottomNavBar: true,
      linkLogin: "",
      isUserLogin: false,
      statusChat: "default", //default, open, minimize
      resChat: [],
      valMessage: "",
      jmlChatNoRep: 0,
      isLoading: false,
      curScreen: null,
      emailForgotPassword: "",
      statusVoc: "show",
      hideVoc: false,
      isFormVoc: false,
      employes: [],
      valPAP: "",
      isAgree: false,
    };
  },
  beforeMount() {
    const getPlatform = Capacitor.getPlatform();

    this.getReferred();

    this.GetUpdateApi();

    this.curScreen = screen.width;
    localStorage.IsClose = "false";
    if (localStorage.Kode) {
      if (localStorage.Email.length > 0) {
        this.UserNameLogin = localStorage.Email;
      } else {
        this.UserNameLogin = localStorage.Telp;
      }
      this.isUserLogin = true;
    }

    this.linkLogin = window.location.pathname;

    if (this.linkLogin.search("MasterWebKSM") >= 0) {
      location.href = "https://admin.kartikasari.co.id";
    }

    /* if(this.linkLogin == '/LoginUser' || ((this.linkLogin.search("DetailCar") >= 0
              || this.linkLogin.search("DetailProduct") >= 0) && this.isMobile() )) */
    if (
      this.linkLogin == "/LoginUser" ||
      this.linkLogin == "/coba" ||
      this.linkLogin == "/RemoveAccountKSM"
    ) {
      this.isLogin = true;
    } else {
      this.isLogin = false;
    }

    /* when use link voc */
    if (this.linkLogin == "/Voc") {
      this.isFormVoc = true;
    } else {
      this.isFormVoc = false;
    }

    /* exception for web register */
    if (this.linkLogin == "/RegisterUsers" && this.isMobile() == false) {
      this.isLogin = true;
    }

    if (
      this.linkLogin == "/WORKSHOP" ||
      this.linkLogin == "/ORDERSTATUS" ||
      this.linkLogin == "/SHOPING" ||
      this.linkLogin == "/BRANCH" ||
      this.linkLogin == "/ABOUT" ||
      this.linkLogin == "/TOS" ||
      this.linkLogin == "/PAP" ||
      this.linkLogin == "/Profile" ||
      this.linkLogin == "/CreditSimulation" ||
      this.linkLogin == "/UNITS" ||
      this.linkLogin == "/CompareCar" ||
      this.linkLogin.search("/DetailCar") >= 0 ||
      this.linkLogin.search("/DetailProduct") >= 0 ||
      this.linkLogin == "/HISTORYNOTIF" ||
      this.linkLogin.search("/RegisterUsers") >= 0
    ) {
      this.isTopNavBar = true;
    }
    if (
      this.linkLogin.search("/DetailProduct") >= 0 ||
      this.linkLogin.search("/RegisterUsers") >= 0
    ) {
      this.isBottomNavBar = false;
    }
    if (this.linkLogin == "/HOMESERVICE" || this.linkLogin == "/WORKSHOP") {
      this.isBottomNavBar = false;
    }

    this.getChatCust();

    //coba get data chat baru
    this.GetRealTimeChat();

    this.getPaP();
    this.checkingAgree();
  },
  created() {
    window.addEventListener("beforeunload", this.clearStorage);
    /*
          document.addEventListener('visibilitychange', function() {
              console.log("visibility on change");
          });
          */
    /*
          window.addEventListener('beforeunload', (event) => {
            // Cancel the event as stated by the standard.
            event.preventDefault();
            // Chrome requires returnValue to be set.
            event.returnValue = '';
          });
          */
    /*
          document.addEventListener('pause', (event) => {
              console.log("on pause in active");
              event.preventDefault();
              event.returnValue = '';
          });
          */
    /*
          if (window.history && window.history.pushState) {
            $(window).on('popstate', function() {
              alert('Back button was pressed.');
            });
          }

          */
    if (this.isMobile() == false) {
      try {
        firebase
          .messaging()
          .requestPermission()
          .then(() => {
            //console.log("Notification permission granted");
            firebase
              .messaging()
              .getToken()
              .then((token) => {
                console.log("token Web", token);
                //localStorage.tokenFirebase = token;
                this.receiveMessage();
              });
          })
          .catch((err) => {
            console.log("Unable to get token ", err);
          });
      } catch (e) {
        console.log(e);
      }
    }
  },
  updated() {
    setTimeout(function () {
      if (this.statusChat == "open") {
        document.getElementById("scrollChat").scrollTop =
          document.getElementById("scrollChat").scrollHeight;
        $("#valueChat").focus();
      }
    }, 150);
  },
  beforeCreate() {
    const myPlatform = Capacitor.getPlatform();
    if (myPlatform != "web") {
      App.addListener("appStateChange", ({ isActive }) => {
        if (isActive == false) {
          localStorage.isCheckUpdated = "false";
          localStorage.firstLoadApk = "false";
        }
      });
    }
  },
  beforeUnmount() {
    localStorage.firstLoadApk = "false";
    localStorage.isCheckUpdated = "false";
  },
  mounted() {
    setInterval(this.GetRealTimeChat, 5000);

    setTimeout(() => {
      this.isLoading = true;
    }, 1000);

    document.addEventListener(
      "backbutton",
      function (e) {
        e.preventDefault();

        var isModalQrShow = $("#modalQr").hasClass("show");
        if (isModalQrShow == true) {
          $("#modalQr").modal("hide");
        } else if (window.location.pathname == "/") {
          $("#modalClose").modal("show");
        } else if (localStorage.isFormServiceOpen == "true") {
          localStorage.isFormServiceOpen = "false";
          window.location.reload();
        } else {
          history.back();
        }
      },
      false
    );

    //new
    if (this.isMobile() == true) {
      this.pushInit();
    }
  },
};
</script>
