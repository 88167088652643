<template>
  <div class="container-fluid match-parent login-block mt-5">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-12 d-flex justify-content-center">
        <div class="card col-md-4 login-sec">
          <form class="login-form" id="applyForm">
            <h2 class="text-center">KARTIKASARI</h2>
            <div class="form-group">
              <label for="Nik" class="text-uppercase float-left">NIK</label>
              <input
                type="text"
                class="form-control"
                name="Nik"
                id="Nik"
                placeholder="NIK"
                v-model="formData.nik"
                @blur="checkNik"
              />
            </div>
            <div class="form-group">
              <label for="userID" class="text-uppercase float-left"
                >UserName</label
              >
              <input
                type="text"
                class="form-control"
                name="userID"
                id="userID"
                placeholder="User ID"
                v-model="formData.nama"
              />
            </div>
            <div class="form-group">
              <label for="phone" class="text-uppercase float-left">Phone</label>
              <input
                type="tel"
                class="form-control"
                name="phone"
                id="phone"
                placeholder="+62"
                v-model="formData.telpn"
              />
            </div>
            <div class="form-group">
              <label for="email" class="text-uppercase float-left">Email</label>
              <input
                type="email"
                class="form-control"
                name="email"
                id="email"
                placeholder="kartikasari@gmail.com"
                v-model="formData.email"
              />
            </div>
            <div class="form-group">
              <label for="refered" class="text-uppercase float-left"
                >Refered By</label
              >
              <a
                class="btn btn-sm btn-ksm my-1 py-0 px-1 border float-right"
                data-toggle="modal"
                data-target="#modalQr"
                href="javascript:void(0)"
                >Scan QR Kode</a
              >
              <!-- <input type="text" class="form-control" name="refered" id="refered" 
                                placeholder="kartikasari" v-model="formData.refby"> -->
              <input
                type="text"
                placeholder="Kartikasari"
                class="form-control"
                id="refered"
                list="dataSales"
                @click="selectSales"
                v-model="curSales"
              />
              <datalist id="dataSales">
                <option v-for="(emp, index) in employes" :key="index">
                  {{ emp.NamaLengkap }}
                </option>
              </datalist>
            </div>
            <div class="form-group">
              <label for="password" class="text-uppercase float-left"
                >Password</label
              >
              <input
                type="password"
                class="form-control"
                id="password"
                name="password"
                placeholder="Password"
                v-model="formData.password"
              />
            </div>
            <div class="form-group">
              <label for="repassword" class="text-uppercase float-left"
                >re-Password</label
              >
              <input
                type="password"
                class="form-control"
                id="repassword"
                name="repassword"
                placeholder="re-Password"
                v-model="repassword"
                @change="checkRePass"
              />
            </div>
          </form>
          <button
            type="button"
            class="btn btn-login btn-block"
            id="btnLogin"
            @click="RegisterCust"
          >
            Register
          </button>

          <div
            class="g-signin2 mt-4"
            data-height="35"
            data-longtitle="true"
            data-onsuccess="onSignIn"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="modalQr"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Scan Atau Ambil QR Kode
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <qrcode-capture
                @detect="onDetect"
                @error="onError"
                :capture="user"
                class="w-100 pb-2"
              >
              </qrcode-capture>
            </div>
            <div v-if="this.isMobile()" class="col-12">
              <div class="card rounded border shadow" style="min-height: 400px">
                <qrcode-stream
                  @detect="onDetect"
                  @error="onError"
                ></qrcode-stream
                ><br />
              </div>
            </div>
            <div v-else class="col-12">
              <label class="text-center text-info"
                >Device Tidak Support Untuk Scan</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import { nytMixin } from "../assets/js/myMixin.js";
import { loadingController } from "@ionic/vue";
import {
  Plugins,
  Capacitor,
  PushNotificationActionPerformed,
  PushNotificationToken,
  Geolocation,
} from "@capacitor/core";
const { PushNotifications, LocalNotifications, Device } = Plugins;

import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";

export default {
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  mixins: [nytMixin],
  data() {
    return {
      formData: {
        nik: "",
        nama: "",
        telpn: "",
        email: "",
        refby: "",
        password: "",
        token: "b4f392359aad35335be8eac4cf9d7f5c",
        deviceIdReg: "",
        //istest: 'TEST'
      },
      employes: [],
      curSales: "",
      //curSales: this.$route.params.param,
    };
  },
  beforeMount() {
    this.getReferred();
    this.getInfoDevice();
    if (localStorage.Kode) {
      //router.push("/");
      window.location.href = "/";
    }
    if (this.$route.params.param != undefined) {
      this.curSales = this.$route.params.param;
    }
  },
  methods: {
    async checkNik() {
      var form_data = {};
      form_data["Token"] = this.formData.token;
      form_data["NIK"] = this.formData.nik;
      const resultData = await this.FuncPostSecond(
        this.urlSite + "/Api/CekNik",
        form_data
      );
      if (resultData.data.status.search("Success") >= 0) {
        if (resultData.data.data) {
          this.formData.nama = resultData.data.data.Perusahaan;
          this.formData.telpn = resultData.data.data.Telp;
          this.formData.email = resultData.data.data.Email;
        } else {
          this.formData.nama = "";
          this.formData.telpn = "";
          this.formData.email = "";
        }
      } else {
        alert("Failed, Server Error");
      }
    },
    onError(err) {
      if (this.isMobile()) {
        alert(err.message);
      }
    },
    onDetect(detectedCodes) {
      //console.log(detectedCodes[0].rawValue);
      var isFound = this.employes.filter(
        (x) => x.NamaLengkap == detectedCodes[0].rawValue
      );
      if (isFound.length > 0) {
        this.curSales = detectedCodes[0].rawValue;
      } else {
        alert("Referensi Sales Tidak Ditemukan");
      }
      $("#modalQr").modal("hide");
    },
    async getInfoDevice() {
      const info = await Device.getInfo();
      this.formData.deviceIdReg = info.uuid;
    },
    async getReferred() {
      var form_data = {};
      const resultData = await this.FuncGet("GetReferred", form_data);
      this.employes = resultData.data;
    },
    async selectSales(event) {
      const thisEvent = event.target.value;
      const getFilter = this.employes.filter((x) => x.NamaLengkap == thisEvent);
      if (getFilter.length > 0) {
        this.formData.refby = getFilter[0].KodeStaff;
        this.curSales = getFilter[0].NamaLengkap;
      } else {
        this.formData.refby = thisEvent;
      }
    },
    async RegisterCust() {
      var isAnyEmpty = false;
      $("#applyForm *")
        .filter(":input")
        .each(function (key, value) {
          //console.log("oke", key, this.value);
          if (this.value == "") {
            isAnyEmpty = true;
          }
        });

      if (isAnyEmpty == true) {
        alert("Lengkapi Form Anda");
      } else {
        const loading = await loadingController.create({
          message: "Please Wait...",
          spinner: "circles",
        });
        loading.present();

        const retRegister = await this.FuncPost(
          "https://apps.kartikasari.co.id/api/RegisterAppsNew",
          this.formData
        );
        //const retRegister = await this.FuncPost('https://kartikasari.co.id/HttpApi/RegisterCalonCust', this.formData);
        //console.log("res register", JSON.stringify(retRegister.data.result[0]));
        var getData = JSON.stringify(retRegister.data.result).search(
          "Sudah Terdaftar"
        );
        if (getData >= 0) {
          alert(retRegister.data.result[0].StatusRegister);
        } else {
          window.location.href = "/LoginUser";
        }

        loading.dismiss();
      }
    },
    checkRePass(event) {
      const rePass = event.target.value;
      if (rePass != this.formData.password) {
        alert("Password Tidak Sama");
      }
    },
  },
};
</script>
<style>
.match-parent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
</style>
