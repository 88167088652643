<template>
    <div v-if="this.isMobile()" class="row " style="margin-top: 15px;"></div>
    <div v-else class="row " 
        :style="curScreen <= 991 && curScreen > 760 ? 'margin-top: 120px !important;' 
            : 'margin-top: 70px !important;'"></div>
    <div class="row w-100 m-0 p-0">
        
        <div :class="this.isMobile() ? 'col-12' : 'col-6 offset-3 card shadow' " class="text-left " style="padding-bottom: 75px;;" >
                <img :class="this.isMobile() ? '' : 'mt-3' " class="mx-auto d-block" src="@/assets/images/profile.png" style="height: 75px;">
            <!--<form> -->
                <div class="form-group">
                    <label for="NamaLengkap">Nama Lengkap</label>
                    <input type="text" id="NamaLengkap" class="form-control form-control-sm" 
                        placeholder="Nama Lengkap" 
                        v-model="profileCust.Perusahaan" readonly>
                </div>
                <div class="form-group">
                    <label>Gender</label>
                    <br>
                    <div class="form-check">
                        <div class="col-6 d-inline">
                            <input class="form-check-input" type="radio" name="Gender" id="genderMale" 
                                value="0" :checked="profileCust.Gender == 0" 
                                v-model="profileCust.Gender">
                            <label class="form-check-label" for="genderMale">
                                Male
                            </label> 
                        </div>
                        <div class="col-6 d-inline">
                            <input class="form-check-input" type="radio" name="Gender" id="genderFemale" 
                                value="1" :checked="profileCust.Gender == 1" 
                                v-model="profileCust.Gender">
                            <label class="form-check-label" for="genderFemale">
                                Female
                            </label> 
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Email">Email</label>
                    <input type="text" id="Email" class="form-control form-control-sm" 
                        placeholder="Email" v-model="profileCust.Email">
                </div>
                <div class="form-group">
                    <label for="Phone">Phone</label>
                    <input type="text" id="Phone" class="form-control form-control-sm" 
                        placeholder="Phone" v-model="profileCust.Telp">
                </div>
                <div class="form-group">
                    <label for="Nik">NIK</label>
                    <input type="text" id="Nik" class="form-control form-control-sm" 
                        placeholder="NIK" v-model="profileCust.NIK" readonly>
                </div>
                <div class="form-group">
                    <label for="Alamat">Alamat</label>
                    <input type="text" id="Alamat" class="form-control form-control-sm" 
                        placeholder="Alamat" v-model="profileCust.Alamat" readonly>
                </div>
                <div class="form-group">
                    <label for="Kota">Kota</label>
                    <input type="text" id="Kota" class="form-control form-control-sm" 
                        placeholder="Kota" v-model="profileCust.Kota" readonly>
                </div>
                <div class="form-group">
                    <label for="TglLahir">Tgl Lahir</label>
                    <input type="text" id="TglLahir" class="form-control form-control-sm" 
                        placeholder="Tgl Lahir" v-model="profileCust.TglLahirOwner" readonly>
                </div>
                <div class="row w-100 m-0 p-0 d-flex justify-content-center">
                    <div class="col-3 ">
                        <button class="btn btn-sm btn-warning btn-block text-white" 
                            @click="updateData()">Update</button>
                    </div>
                    <div class="col-6  ">
                        <button class="btn btn-sm btn-danger btn-block" @click="ShowModal()">Change Password</button>
                    </div>
                    <!--<div v-if="this.isIos() == true && profileCust.Perusahaan.search('000110') >= 0" class="col-3 ">-->
                    <div class="col-3 ">
                        <button class="btn btn-sm btn-info" @click="setActive">Delete</button>
                    </div>
                </div>
            <!--</form>-->
        </div>
    </div> 

    <modal ref="mymodal" />

</template>
<script>
import { nytMixin } from "../assets/js/myMixin.js";
import modal from "../components/ModalChangePassword.vue";
export default {
    components: { modal },
    mixins: [nytMixin],
    data() {
        return{
            profileCust:[],
            curScreen: screen.width,
        }
    },
    setup() {
        
    },
    beforeMount(){
        //console.log(localStorage.Kode);
        this.getDetailCust(localStorage.Kode);
    },
    methods:{
        async setActive(){
            var form_data = {};
            form_data['codeCust'] = localStorage.Kode;
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/ChangeActive", form_data);
            if(resultData.data.search("Success") >= 0)
            {
                const dataReturn = this.funcLogoutSecond();
                        if(dataReturn)
                        {
                            window.location.href="/";
                        }		
            }
            else {
                alert("Failed, Check Your Connection!");
            }
        },
        async getDetailCust(param) {
            var form_data = {};   
            form_data['codeCust'] = param; 
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetProfileCust", form_data);
            //console.log(resultData.data);
            this.profileCust = resultData.data;
        },
        async updateData(){
            if(this.profileCust.Email == '' || this.profileCust.Telp == '')
            {
                alert("Lengkapi Form Anda");
            }
            else
            {
                var form_data = {};
                form_data['Kode'] = localStorage.Kode;
                form_data['Email'] = this.profileCust.Email;
                form_data['Phone'] = this.profileCust.Telp;
                form_data['Gender'] = this.profileCust.Gender;
                var resultData = await this.FuncPost(this.urlSite + "/HttpApi/UpdateCust", form_data);
                if(resultData.data.search("Success") >= 0)
                {
                    window.location.href="/SETTING";
                }
                else
                {
                    alert("Gagal Update Data");
                }
            }
        },
        ShowModal(){
            this.$refs.mymodal.showModal();
        }
    }
}
</script>