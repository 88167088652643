<template>
  <div class="modal fade right" id="modalFinish" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
    <div class="modal-content text-left">
      <div class="modal-header my-0 py-0 ">
        <h5 class="modal-title m-0 p-0"><small>Detail Order Selesai</small></h5>
        <button type="button" @click="hideModal" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div v-if="dataType == 'SO'">
          <div v-if="dataResults != undefined" v-for="(spare, index) in dataResults" :key="index" class="row p-0 m-0  mb-2 ">
            <div class="col-12 p-0 m-0">
              <div class="card w-100 m-0 p-0">
                <div class="card-header my-0 py-0">
                <small>Informasi Pemesanan Sparepart</small> 
                </div>
                <div class="card-body">
                <div class="row">
                  <div class="col-4"><small><p class=" pt-0 pb-0 mt-0 mb-1">Nomor Booking</p></small></div>
                  <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataInvoice}}</p></small></div>
                </div>
                <div class="row">
                  <div class="col-4"><small><p class=" pt-0 pb-0 mt-0 mb-1">Tgl Booking</p></small></div>
                  <div class="col-8">
                                  <small>
                                      <p v-if="spare.TglPesan" class=" pt-0 pb-0 mt-0 mb-1">{{spare.TglPesan}}</p>
                                      <p v-else class=" pt-0 pb-0 mt-0 mb-1">-</p>
                                  </small>
                              </div>
                </div>
                <div class="row">
                  <div class="col-4"><small><p class=" pt-0 pb-0 mt-0 mb-1">Phone</p></small></div>
                  <div class="col-8"><small>
                                  <p v-if="spare.PhonePenerima" class=" pt-0 pb-0 mt-0 mb-1">{{spare.PhonePenerima}} </p>
                                  <p v-else class=" pt-0 pb-0 mt-0 mb-1">- </p>
                              </small></div>
                </div>
                <div class="row">
                  <div class="col-4"><small><p class=" pt-0 pb-0 mt-0 mb-1">Alamat</p></small></div>
                  <div class="col-8"><small>
                                  <p v-if="spare.AlamatPenerima" class=" pt-0 pb-0 mt-0 mb-1">{{spare.AlamatPenerima}}</p>
                                  <p v-else class=" pt-0 pb-0 mt-0 mb-1">-</p>
                              </small></div>
                </div>
                <div class="row">
                  <div class="col-4"><small><p class=" pt-0 pb-0 mt-0 mb-1">Resi</p></small></div>
                  <div class="col-8"><small>
                                  <p v-if="spare.NoResi" class=" pt-0 pb-0 mt-0 mb-1">{{spare.NoResi}}</p>
                                  <p v-else class=" pt-0 pb-0 mt-0 mb-1">-</p>
                              </small></div>
                </div>
                <div class="row">
                  <div class="col-4"><small><p class=" pt-0 pb-0 mt-0 mb-1">Kurir</p></small></div>
                  <div class="col-8"><small>
                                  <p v-if="spare.Kurir" class=" pt-0 pb-0 mt-0 mb-1">{{spare.Kurir}}</p>
                                  <p v-else class=" pt-0 pb-0 mt-0 mb-1">-</p>
                              </small></div>
                </div>
                
                </div> 
              </div>
            </div>
          </div>
          <div class="row p-0 m-0  mb-2">
            <div class="col-12 p-0 m-0">
              <div class="card w-100 m-0 p-0">
                <div class="card-header my-0 py-0">
                <small>Detail Pembelian</small> 
                </div>
                <div class="card-body">
                <table class="table">
                  <thead>
                    <th><small>No</small></th>
                    <th><small>Nama</small></th>
                    <th><small>QT</small></th>
                    <th><small>Satuan</small></th>
                  </thead>
                  <tbody>
                    <span v-if="dataResutls != undefined && dataResults[0]">
                        <tr v-if="dataResults[0].NamaProduct != null" 
                            v-for="(spare, index) in dataResults" :key="index">
                            <td><small>{{index + 1}}</small></td>
                            <td><small>{{spare.NamaProduct}}</small></td>
                            <td class=""><small>{{spare.Jml}}</small></td>
                            <td class="float-right"><small>{{spare.HrgSatuanPPN}}</small></td>
                        </tr>	
                    </span>
                          
                    <span v-if="dataResults != undefined && dataResults[0]">
                        <tr v-if="dataResults[0].Kurir != null">
                            <td><small>{{dataResults.length + 1 }}</small></td>
                            <td>
                                <small v-if="dataResults[0]">{{dataResults[0].Kurir}}</small>
                                <small v-else>0</small>
                            </td>
                            <td><small>1</small></td>
                            <td class="float-right">
                                <small v-if="dataResults[0]">{{dataResults[0].Ongkir}}</small>
                                <small v-else>0</small>
                            </td>
                        </tr>
                    </span>
                    
                    <tr v-if="totalBayar > 0">
                        <td colspan="3"><small>Total Bayar</small></td>
                        <td class="float-right"><small>{{totalResBayar}}</small></td>
                    </tr>
                  </tbody>
                </table>
                </div> 
              </div>
            </div>
          </div>
        </div>

        <!-- service -->
        <div v-if="dataType == 'Service'" class="row p-0 m-0 mb-2 w-100">
          <div class="col-12 p-0 mx-0 mb-2 mt-0">
            <div class="card w-100 p-0 m-0">
              <div class="card-header">
              <strong>Informasi Pemesanan</strong> 
              </div>
              <div class="card-body" v-if="dataResults[0]" >
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Nomor Wo</p></strong></small></div>
                    <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults[0].KodeNota}}</p></small></div>
                  </div>
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Tgl Booking</p></strong></small></div>
                    <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults[0].Tgl.substring(0,10)}}</p></small></div>
                  </div>
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Phone</p></strong></small></div>
                    <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults[0].TelpRetention}}</p></small></div>
                  </div>
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Alamat</p></strong></small></div>
                    <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults[0].Alamat}}</p></small></div>
                  </div>
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Kendaraan</p></strong></small></div>
                    <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults[0].NamaKendaraan}}</p></small></div>
                  </div>
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Nopol</p></strong></small></div>
                    <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults[0].NoPol}}</p></small></div>
                  </div>
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Tgl Close WO</p></strong></small></div>
                    <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults[0].TglCloseWO.substring(0,10)}}</p></small></div>
                  </div>
              </div> 
            </div>
          </div>
          <div class="row p-0 m-0 mb-2 overflow-auto" >
            <div class="col-12 p-0 m-0">
              <div class="card border-0">
                <div class="card-header">
                  <strong>Faktur Penjualan</strong>
                  <a :href="'/TemplatePdfInvoice/' + dataInvoice.replace(/\//g,'-') + '/' + dataType" style="max-height: 25px; padding: 0px 10px 10px 10px; margin: 0;"
                    class="btn btn-sm btn-ksm-red float-right removeBlueLink text-white" >
                    <small>DOWNLOAD FAKTUR</small>
                  </a>
                  <!--<a href="https://kartikasari.co.id/MasterMobileKSM/GeneratePdf" class="btn btn-sm btn-info float-right">Download</a> -->
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Nomor Invoice</p></strong></small></div>
                    <div class="col-8">
                      <small>
                        <p v-if="fakturs.length > 0" class=" pt-0 pb-0 mt-0 mb-1">
                          {{ fakturs[0].NoInvoice }}
                        </p>
                      </small>
                    </div>
                  </div>
                  <div class="row " >
                    <div class="col-12 ">
                      <table class="table " id="tblFaktur" >
                        <thead>
                          <tr>
                            <th ><small>No.</small></th>
                            <th style="min-width: 200px;"><small>Nama</small></th>
                            <th><small>Status</small></th>
                            <th><small>Jml</small></th>
                            <th style="min-width: 100px;"><small>Hrg. Sat.</small></th>
                            <th ><small>Disc.</small></th>
                            <th style="min-width: 100px;"><small>Jml Disc.</small></th>
                            <th style="min-width: 100px;"><small>Jumlah</small></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(faktur, index) in fakturs" :key="index">
                            <td><small>{{ index + 1 }}</small></td>
                            <td><small>{{ faktur.Keterangan }}</small></td>
                            <td><small>{{ faktur.Status }}</small></td>
                            <td class="text-right"><small>{{ Math.floor( parseFloat(faktur.Jml) * 100 ) / 100 }}</small></td>
                            <td class="text-right"><small>{{ faktur.HrgSatuan }}</small></td>
                            <td class="text-right"><small>{{ faktur.Disc }}%</small></td>
                            <td class="text-right"><small>{{ faktur.JmlDisc }}</small></td>
                            <td class="text-right"><small>{{ faktur.SubTotal }}</small></td>
                          </tr>
                          
                          <tr></tr>
                          <tr>
                            <td class="text-right" colspan="7"><small>Total</small></td>
                            <td class="text-right"><small>Rp{{ parseFloat(totalFaktur).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }}</small></td>
                          </tr>
                          <tr><td colspan="8" class="text-right"><small><em>*Harga Belum Termasuk PPN</em></small></td></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row p-0 m-0  mb-2">
            <div class="col-12 p-0 m-0">
              <div class="card w-100 p-0 m-0">
                <div class="card-header">
                <strong>Status Pemesanan</strong> 
                </div>
                <div class="card-body">
                    <div class="row " v-if="detailPerjalanan" 
                      v-for="(service, index) in detailPerjalanan" :key="index">
                      
                      <div class="col-12 w-100 m-0 p-0"><small><p class=" m-0 p-0">
                      Mulai Pekerjaan <strong>{{service.JenisPekerjaan}}</strong> 
                      Pada Tanggal <strong>{{service.Mulai}}</strong>
                      Dengan Nama Mekanik <strong>{{service.Nama}}</strong>
                      <span v-if="service.Akhir">
                          Selesai Pada Tanggal <strong>{{service.Akhir}}</strong>
                      </span>
                      </p></small></div>
                      <div class="col-12 w-100 m-0 p-0">
                        <div  style="
                              border: 1px dashed #000; 
                              width: 100%; 
                              margin: auto; 
                              margin-top: 5%; 
                              margin-bottom: 5%; 
                            " >
                        </div>
                        
                      </div>
                    </div>
                  
                
                </div> 
              </div>
            </div>
          </div>
        </div>

        <!-- Beli -->
        <div v-if="dataType == 'Beli'">
        <div class="row p-0 m-0  mb-2">
          <div class="col-12 p-0 m-0">
            <div class="card w-100 m-0 p-0">
              <div class="card-header">
              <strong>Informasi Pemesanan Unit</strong> 
              </div>
              <div class="card-body">
              <div class="row d-flex justify-content-center">
                <div class="col-2 p-0 m-0  d-flex justify-content-center">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img style="max-height: 25px;"  :src="this.urlSite + '/assets/img/iconconfirm.png'">
                    </div>
                    
                  </div>
                </div>
                <div class="col-2 p-0 m-0  d-flex justify-content-center">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img style="max-height: 25px;"  :src="this.urlSite + '/assets/img/iconfollowup.png'">
                    </div>
                    
                  </div>				
                </div>
                <div class="col-2 p-0 m-0  d-flex justify-content-center">
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img style="max-height: 25px;"  :src="this.urlSite + '/assets/img/iconpacking.png'">
                    </div>
                    
                  </div>					
                </div>
                <div class="col-2 p-0 m-0  d-flex justify-content-center" >
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img style="max-height: 25px;"  :src="this.urlSite + '/assets/img/iconcourier.png'">
                    </div>
                    
                  </div>					
                </div>
                <div class="col-2 p-0 m-0  d-flex justify-content-center"> 
                  <div class="row">
                    <div class="col-12 d-flex justify-content-center">
                      <img style="max-height: 25px;"  :src="this.urlSite + '/assets/img/icondelivered.png'">
                    </div>
                    
                  </div>					
                </div>
                <div class="col-12 d-flex justify-content-center p-0 mt-2">
                  <div class="progress col-8 p-0 m-0" style="height: 4px;">
                    <div v-if="dataResults.StatusSelesai == 'Konfirmasi Ke Pelapak' && dataResults.StatusBPKB == ''" 
                      class="progress-bar bg-red-ksm" role="progressbar" 
                      style="width: 0%" aria-valuenow="0" 
                      aria-valuemin="0" aria-valuemax="100">
                    </div>
                    <div v-else-if="dataResults.StatusSelesai == 'Follow Up Pelapak' && dataResults.StatusBPKB == ''" 
                      class="progress-bar bg-red-ksm" role="progressbar" 
                      style="width: 25%" aria-valuenow="25" 
                      aria-valuemin="0" aria-valuemax="100">
                    </div>
                    <div v-else-if="dataResults.TglNoticePlat != ''" 
                      class="progress-bar bg-red-ksm" role="progressbar" 
                      style="width: 50%" aria-valuenow="50" 
                      aria-valuemin="0" aria-valuemax="100">
                    </div>
                    <div v-else-if="dataResults.StatusBPKB.search('BPKB Pada') >= 0" 
                      class="progress-bar bg-red-ksm" role="progressbar" 
                      style="width: 75%" aria-valuenow="75" 
                      aria-valuemin="0" aria-valuemax="100">
                    </div>
                    <div v-else-if="dataResults.StatusBPKB.search('BPKB Customer') >= 0" 
                      class="progress-bar bg-red-ksm" role="progressbar" 
                      style="width: 100%" aria-valuenow="100" 
                      aria-valuemin="0" aria-valuemax="100">
                    </div>

                  </div>
                </div>
                <div class="col-12 p-0 mt-n2 d-flex justify-content-center">
                  <div class="col-2 p-0 m-0  d-flex justify-content-center">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <img style="max-height: 15px;" :src="this.urlSite + '/assets/img/iconchecklist.png'">
                      </div>
                    </div>
                  </div>
                  <div class="col-2 p-0 m-0  d-flex justify-content-center">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <img style="max-height: 15px;" :src="this.urlSite + '/assets/img/iconchecklist.png'">
                      </div>
                    </div>				
                  </div>
                  <div class="col-2 p-0 m-0  d-flex justify-content-center">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <img style="max-height: 15px;" :src="this.urlSite +'/assets/img/iconchecklist.png'">
                      </div>
                    </div>					
                  </div>
                  <div class="col-2 p-0 m-0  d-flex justify-content-center" >
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <img style="max-height: 15px;" :src="this.urlSite + '/assets/img/iconchecklist.png'">
                      </div>
                    </div>					
                  </div>
                  <div class="col-2 p-0 m-0  d-flex justify-content-center"> 
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <img style="max-height: 15px;" :src="this.urlSite + '/assets/img/iconchecklist.png'">
                      </div>
                    </div>					
                  </div>
                  
                </div>
                <div class="col-12 p-0 mt-2 d-flex justify-content-center">
                  <p><small>
                    <strong v-if="dataResults.StatusSelesai == 'Konfirmasi Ke Pelapak' && dataResults.StatusBPKB == ''">
                      {{dataResults.StatusSelesai}}
                    </strong>
                    <strong v-else-if="dataResults.StatusSelesai == 'Follow Up Pelapak' && dataResults.StatusBPKB == ''">
                      {{dataResults.StatusSelesai}}
                    </strong>
                    <strong v-else-if="dataResults.TglNoticePlat != ''">
                      Di Proses Pelapak
                    </strong>
                    <strong v-else-if="dataResults.StatusBPKB.search('BPKB Pada') >= 0">
                      Proses Pengiriman
                    </strong>
                    <strong v-else-if="dataResults.StatusBPKB.search('BPKB Customer') >= 0">
                      Pesanan Selesai
                    </strong>
                  </small></p>
                </div>
              </div>
              </div> 
            </div>
          </div>
        </div>
        <div class="row p-0 m-0  mb-2">
          <div class="col-12 p-0 m-0">
            <div class="card w-100 p-0 m-0">
              <div class="card-header">
              <strong>Informasi Pemesanan</strong> 
              </div>
              <div class="card-body">
              <div class="row">
                <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Invoice</p></strong></small></div>
                <div class="col-8"><small>
                  <p class=" pt-0 pb-0 mt-0 mb-1" v-if="dataResults.Invoice">{{dataResults.Invoice}}</p>
                  <p class=" pt-0 pb-0 mt-0 mb-1" v-else>{{dataResults.KodeNota}}</p>
                </small></div>
              </div>
              <div class="row">
                <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Nama Unit</p></strong></small></div>
                <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults.CarName}}</p></small></div>
              </div>
              <div class="row">
                <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Harga</p></strong></small></div>
                <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults.CarPrice}}</p></small></div>
              </div>
              <div class="row">
                <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Alamat</p></strong></small></div>
                <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults.AlamatPenerima}}</p></small></div>
              </div> 
              <div class="row">
                <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Pengiriman</p></strong></small></div>
                <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults.Kurir}}</p></small></div>
              </div>
              <div class="row">
                <div class="col-4"><small><strong><p class=" pt-0 pb-0 mt-0 mb-1">Nomer Resi</p></strong></small></div>
                <div class="col-8"><small><p class=" pt-0 pb-0 mt-0 mb-1">{{dataResults.NoResi}}</p></small></div>
              </div>
              </div> 
            </div>
          </div>
        </div>
        
        <div class="row p-0 m-0  mb-2">
          <div class="col-12 p-0 m-0">
            <div class="card w-100 p-0 m-0">
              <div class="card-header">
              <strong>Status Pemesanan</strong> 
              </div>
              <div class="card-body">
                <div class="row mb-2" v-if="dataResults.KodeNota">
                  <div class="col-12 w-100 m-0 p-0"><small><p class="m-0 p-0">
                    SPK telah diterbitkan dengan nomor <strong>{{dataResults.KodeNota}}</strong>
                    pada tanggal <strong>{{dataResults.TglSPK}}</strong></p></small>
                  </div>
                  <div class="col-12 w-100 m-0 p-0">
                    <div  style="
                      border: 1px dashed #000; 
                      width: 100%; 
                      margin: auto; 
                      margin-top: 5%; 
                      margin-bottom: 5%;
                    " class="mb-1 d-flex justify-content-center">
                    </div>
                    
                  </div>
                </div>
                  <div class="row mb-2" v-if="dataResults.NoRangka">
                    <div class="col-12 w-100 m-0 p-0"><small><p class=" m-0 p-0">
                      Sudah mendapatkan Nomor Rangka dengan nomor 
                      <strong>{{dataResults.NoRangka}}</strong></p></small>
                    </div>
                    <div class="col-12 w-100 m-0 p-0">
                    <div  style="
                      border: 1px dashed #000; 
                      width: 100%; 
                      margin: auto; 
                      margin-top: 5%; 
                      margin-bottom: 5%;
                    " class="mb-1 d-flex justify-content-center">
                    </div>
                    
                  </div>
                  </div> 
                    
                  <div class="row mb-2" v-if="dataResults.TglSIKK">
                    <div class="col-12 w-100 m-0 p-0"><small><p class="m-0 p-0">
                      Pengiriman unit pada tanggal 
                      <strong>{{dataResults.TglSIKK}}</strong></p></small>
                    </div>
                    <div class="col-12 w-100 m-0 p-0">
                    <div  style="
                      border: 1px dashed #000; 
                      width: 100%; 
                      margin: auto; 
                      margin-top: 5%; 
                      margin-bottom: 5%;
                    " class="mb-1 d-flex justify-content-center">
                    </div>
                    
                  </div>
                  </div>
                    
                    <div class="row mb-2" v-if="dataResults.TglPengajuanFaktur">
                      <div class="col-12 w-100 m-0 p-0"><small><p class="m-0 p-0">
                        Faktur diajukan pada tanggal 
                        <strong>{{dataResults.TglPengajuanFaktur}}</strong></p></small>
                      </div>
                      <div class="col-12 w-100 m-0 p-0">
                        <div  style="
                          border: 1px dashed #000; 
                          width: 100%; 
                          margin: auto; 
                          margin-top: 5%; 
                          margin-bottom: 5%;
                        " class="mb-1 d-flex justify-content-center">
                        </div>
                        
                      </div>
                    </div> 

                    <div class="row mb-2" v-if="dataResults.TglPenerimaanFaktur">
                      <div class="col-12 m-0 p-0"><small><p class="m-0 p-0">
                        Faktur selesai pada tanggal <strong>{{dataResults.TglPenerimaanFaktur}}</strong></p></small>
                      </div>
                      <div class="col-12 w-100 m-0 p-0">
                        <div  style="
                          border: 1px dashed #000; 
                          width: 100%; 
                          margin: auto; 
                          margin-top: 5%; 
                          margin-bottom: 5%;
                        " class="mb-1 d-flex justify-content-center">
                        </div>
                        
                      </div>
                    </div> 
                    
                    <div class="row mb-2" v-if="dataResults.TglNoticePlat">
                      <div class="col-12 w-100 m-0 p-0"><small><p class="m-0 p-0">
                        {{dataResults.TglNoticePlat}}</p></small>
                      </div>
                      <div class="col-12 w-100 m-0 p-0">
                        <div  style="
                          border: 1px dashed #000; 
                          width: 100%; 
                          margin: auto; 
                          margin-top: 5%; 
                          margin-bottom: 5%;
                        " class="mb-1 d-flex justify-content-center">
                        </div>
                        
                      </div>
                    </div>

                    <div class="row mb-2" v-if="dataResults.StatusSTNK">
                      <div class="col-12 w-100 m-0 p-0">
                        <small>
                          <p class="w-0 p-0" v-html="dataResults.StatusSTNK"></p>
                        </small>
                      </div>
                      <div class="col-12 w-100 m-0 p-0">
                        <div  style="
                          border: 1px dashed #000; 
                          width: 100%; 
                          margin: auto; 
                          margin-top: 5%; 
                          margin-bottom: 5%;
                        " class="mb-1 d-flex justify-content-center">
                        </div>
                        
                      </div>
                    </div>

                    <div class="row mb-2" v-if="dataResults.FormA">
                      <div class="col-12 w-100 m-0 p-0">
                        <small>
                          <p class="m-0 p-0" v-html="dataResults.FormA"></p>
                        </small>
                      </div>
                      <div class="col-12 w-100 m-0 p-0">
                        <div  style="
                          border: 1px dashed #000; 
                          width: 100%; 
                          margin: auto; 
                          margin-top: 5%; 
                          margin-bottom: 5%;
                        " class="mb-1 d-flex justify-content-center">
                        </div>
                        
                      </div>
                    </div>

                    <div class="row mb-2" v-if="dataResults.StatusBPKB">
                      <div class="col-12 w-100 m-0 p-0">
                        <small>
                          <p class="m-0 p-0" v-html="dataResults.StatusBPKB"></p>
                        </small>
                      </div>
                      <div class="col-12 w-100 m-0 p-0">
                        <div  style="
                          border: 1px dashed #000; 
                          width: 100%; 
                          margin: auto; 
                          margin-top: 5%; 
                          margin-bottom: 5%;
                        " class="mb-1 d-flex justify-content-center">
                        </div>
                        
                      </div>
                    </div>
                  </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
	  
    </div>
  </div>

</div>
</template>


<script>
import $ from 'jquery';
import { nytMixin } from "../assets/js/myMixin.js";

export default {
    mixins: [nytMixin],
    props: [],
    setup() {
        
    },
    data(){
        return{
            dataInvoice: '',
            dataType: '',
            retData: [],
            dataResults: [],
            detailPerjalanan: [],
            totalResBayar : 0,
            fakturs: [],
            totalFaktur: 0,
        }
    },
    beforeMount(){

    },
    mounted() {

    },
    updated(){
      /*
      setTimeout(function() 
      { 
        $("#tblFaktur").DataTable({
          "lengthChange": false,
          "autoWidth": false,
          "retrieve": true,
        }); 
      }, 2000);
      */ 
    },
    methods:{
        
        showModal() {
            $("#modalFinish").modal("show");
        },
        hideModal() {
            $("#modalFinish").modal("hide");
        },   
        async getDetailFinish(invoice, type)
        {
            this.dataInvoice = '';
            this.dataType = '';
            this.retData = [];
            this.dataResults = [];
            this.detailPerjalanan = [];
            this.totalResBayar = 0;

            var form_data = {};   
            form_data['invoice'] = invoice;
            form_data['tipe'] = type;
            this.dataType = type;
            this.dataInvoice = invoice;
            const resultData = await this.FuncPost(this.urlSite + "/HttpApi/DetailSelesai", form_data);
            this.retData = resultData.data;
            this.dataResults = resultData.data.results;
            this.detailPerjalanan = resultData.data.detailPerjalanan;
            this.fakturs = resultData.data.fakturs;

            var totalOngkir = 0;
            var totalBayar = 0;
            var subTotalFaktur = 0;

            if(this.fakturs.length > 0)
            {
              this.fakturs.forEach((item)=>{
                subTotalFaktur += parseFloat(item.SubTotal.replace("Rp", "").replace(".", ""));
              });
              this.totalFaktur = subTotalFaktur;
            }
            if(this.dataResults.length > 0)
            {
              this.dataResults.forEach((item)=>{
                  if(item.Ongkir != null)
                  {
                      totalOngkir = parseInt(item.Ongkir);
                  }
                  if(item.SubtotalPPn != null)
                  {
                      totalBayar += parseInt(item.SubtotalPPn);
                  }
              });
              totalBayar += totalOngkir;
              this.totalResBayar = totalBayar;
            }
        }
    }
}
</script>
