<template>
    <div class="row mt-3 m-1 p-1 ">
        <div class="ccol-12 mx-0 mb-2 p-0 w-100">
            <div class="container d-flex justify-content-center w-100 m-0 p-0">
                <div class="card p-3 w-100 m-0 p-0">
                    <div class="d-flex flex-row justify-content-between text-align-center">
                        <p><b>{{ namaCust }}</b><small> {{ calonKodeCust }}</small></p>
                        <i class="fas fa-ellipsis-v"></i>
                    </div>
                    <div class="card-bottom pt-3 px-3 mb-2">
                        <div class="d-flex flex-row justify-content-between text-align-center">
                            <div class="d-flex flex-column flex-columnSetting text-left pb-3">
                                <span>Point Belanja</span>
                                <p class="m-0 p-0" v-for="(nom, index) in nominal" :key="index">
                                    <small><span class="text-white">{{ parseInt(nom.Nominal).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") }} </span>
                                    </small> 
                                    <small>( {{ nom.Status }} )</small></p>
                            </div>
                            <button @click="detailPoint" class="btn btn-secondary btn-secondarySetting btn-sm"><i class="fas fa-arrow-right text-white"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="kupons.length > 0" class="col-12 mb-2 p-0 ">
            <div class="row ml-0 mr-0 d-flex flex-row flex-nowrap overflow-auto  w-100">  
                    <div class="col-8 m-0 p-0 w-100" 
                        v-for="(kupon, index) in kupons" :key="index"
                        @click="detailKupon(kupon.KodeCalon, kupon.Kode, kupon.KodeNota, kupon.Keterangan, kupon.xKali, kupon.Image)">
                        <div id="background" >
                        <img :src="kupon.Image" class="stretch" alt="" />
                        </div>
                    </div>
                <!--
                <div class="col-8 m-0 p-0 w-100" >
                     <div id="background">
                        <img src="@/assets/images/kuponBeliMobil.png" class="stretch" alt="" />
                    </div>
                    <div class="row w-100 m-0 p-0 h-100 align-content-center position-absolute " 
                        style="top:0; left:0;">
                        <div class="col-10 ">
                            <div class="row text-left">
                                <div class="col-12">
                                    <p class="m-0 p-0 p-kupon"><strong>Nomor/Nama Cust</strong></p>
                                    <h2 style="color: #2a51a3;" class="m-0 p-0"><strong>PEMBELIAN MOBIL</strong></h2>
                                    <p class="m-0 p-0 p-kupon"><strong>TUNJUKAN KUPON UNTUK MENDAPATKAN DOORPRIZE MENARIK</strong></p>
                                    <p class="m-0 p-0 p-kupon">BERLAKU SAMPAI <strong>12 DESEMBER 2023</strong></p>
                                    <p class="m-0 p-0"><small>*syarat dan ketentuan berlaku</small></p>
                                    <p class="text-white text-center rounded m-0 p-0" style="background-color: #2a51a3;">085105069000-KARTIKA SARI MALANG</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-2 ">
                            <h4 style="transform: rotate(270deg)">adsf</h4>
                        </div>
                        
                    </div>
                    
                </div>
                -->
            </div>
            
        </div>
        <!--
        <div class="col-12 mx-0 mb-2 p-0 w-100">
            <div class="row ml-0 mr-0 d-flex flex-row flex-nowrap overflow-auto  w-100">
                <div class="col-5 mx-2 p-0 w-100" style="height: 55px;">
                    <div class="card shadow m-0 p-0 w-100" style="height: 50px;">
                        <div class="card-body mx-1 p-0 my-0 text-left">
                            <div class="row w-100 m-0 p-0 h-100 ">
                                <div class="col-10 w-100 m-0 p-0 h-100 ">
                                    <small >
                                        <p class="my-0 p-0">Diskon <b>Innova</b></p>
                                        <p class="my-0 p-0">Rp.5000.000</p>
                                    </small>
                                </div>
                                <div class="col-2 w-100 m-0 p-0">
                                    <i class="fa-solid fa-tags "></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5 mx-2 p-0 w-100" style="height: 55px;">
                    <div class="card shadow m-0 p-0 w-100" style="height: 50px;">
                        <div class="card-body mx-1 p-0 my-0 text-left">
                            <div class="row w-100 m-0 p-0 h-100 ">
                                <div class="col-10 w-100 m-0 p-0 h-100 ">
                                    <small >
                                        <p class="my-0 p-0">Diskon <b>Yaris</b></p>
                                        <p class="my-0 p-0">Rp.5000.000</p>
                                    </small>
                                </div>
                                <div class="col-2 w-100 m-0 p-0">
                                    <i class="fa-solid fa-tags "></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-5 mx-2 p-0 w-100" style="height: 55px;">
                    <div class="card shadow m-0 p-0 w-100" style="height: 50px;">
                        <div class="card-body mx-1 p-0 my-0 text-left">
                            <div class="row w-100 m-0 p-0 h-100 ">
                                <div class="col-10 w-100 m-0 p-0 h-100 ">
                                    <small >
                                        <p class="my-0 p-0">Diskon <b>FT86</b></p>
                                        <p class="my-0 p-0">Rp.5000.000</p>
                                    </small>
                                </div>
                                <div class="col-2 w-100 m-0 p-0">
                                    <i class="fa-solid fa-tags "></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        -->
        <div class="col-4 m-0 p-0">
            <a href="/ABOUT" class="w-100 m-0 p-0 removeBlueLink">
                <div class="card w-100 m-0 p-0 rounded "> 
                    <div class="card-body w-100 m-0 p-0">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-2">  
                                <img src="@/assets/images/about.png" style="height: 50px;"/> 
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label><small style="font-size: 10px;">About</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 w-100 m-0 p-0">
            <a href="/TOS" class="w-100 m-0 p-0 removeBlueLink">
                <div class="card w-100 m-0 m p-0 rounded "> 
                    <div class="card-body w-100 m-0 p-0 ">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-2">  
                                <img src="@/assets/images/term_of_service.png" style="height: 50px;" /> 
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label><small style="font-size: 10px;">Therm Of Service</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 w-100 m-0 p-0">
            <a href="/PAP" class="w-100 m-0 p-0 removeBlueLink">
                <div class="card w-100 m-0 p-0 rounded "> 
                    <div class="card-body w-100 m-0 p-0">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-2">  
                                <img src="@/assets/images/privacy.png" style="height: 50px;"/> 
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label><small style="font-size: 10px;">Privacy Policy</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 w-100 m-0 p-0">
            <a href="/Profile" class="w-100 m-0 p-0 removeBlueLink">
                <div class="card rounded w-100 m-0 p-0"> 
                    <div class="card-body w-100 m-0 p-0">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-2">  
                                <img src="@/assets/images/profile.png" style="height: 50px;"/> 
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label><small style="font-size: 10px;">Profile</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 w-100 m-0 p-0">
            <a href="/BRANCH" class="removeBlueLink">
                <div class="card rounded w-100 m-0 p-0"> 
                    <div class="card-body w-100 m-0 p-0">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-2">  
                                <img src="@/assets/images/branch.png" style="height: 50px;"/> 
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label><small style="font-size: 10px;">Our Branch</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 w-100 m-0 p-0">
            <a href="CreditSimulation" class="removeBlueLink">
                <div class="card rounded w-100 m-0 p-0"> 
                    <div class="card-body w-100 m-0 p-0">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-2">  
                                <img src="@/assets/images/credit_simulation.png" style="height: 50px;"/> 
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label><small style="font-size: 10px;">Credit Simulation</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 w-100 m-0 p-0">
            <a href="/UNITS" class="removeBlueLink">
                <div class="card rounded w-100 m-0 p-0"> 
                    <div class="card-body w-100 m-0 p-0">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-3 h-100">  
                                <img src="@/assets/images/my_unit.png" style="height: 35px; width: 75px;" /> 
                            </div>
                            <div class="col-12 d-flex justify-content-center my-1">
                                <label><small style="font-size: 10px;">My Unit</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="col-4 w-100 m-0 p-0">
            <a href="/HISTORYNOTIF" class="removeBlueLink">
                <div class="card rounded w-100 m-0 p-0"> 
                    <div class="card-body w-100 m-0 p-0">
                        <div class="row w-100 m-0 p-0">
                            <div class="col-12 d-flex justify-content-center mt-2">  
                                <img src="@/assets/images/notification.png" style="height: 50px;" class="d-inline" /> 
                                <p v-if="jmlNotif > 0" class="d-inline">
                                    <span class="badge badge-danger ">{{jmlNotif}}</span>
                                </p>
                            </div>
                            <div class="col-12 d-flex justify-content-center">
                                <label><small style="font-size: 10px;">History & Notif</small></label>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div @click="logout" class="col-4 w-100 m-0 p-0">
            <div class="card rounded w-100 m-0 p-0"> 
                <div class="card-body w-100 m-0 p-0">
                    <div class="row w-100 m-0 p-0">
                        <div class="col-12 d-flex justify-content-center mt-2">  
                            <img src="@/assets/images/profile.png" style="height: 50px;"/> 
                        </div>
                        <div class="col-12 d-flex justify-content-center">
                            <label><small style="font-size: 10px;">Logout</small></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 hideContent" style="min-height: 10px;"></div>
        <div @click="eventMember" class="col-12 w-100 m-0 p-0 hideContent">
            <div class="m-0 p-0 w-100 shadow card roundedAll overflow-hidden" 
                style="height: 120px" >  
                <img src="@/assets/images/GRTeamLogo.jpeg" style="" class="w-100 roundedAll d-block h-100" /> 
            </div>
        </div>
       
    </div>

<!-- Modal Cooming Soon -->
<div class="modal fade" id="modalComingSoon" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
            <img src="@/assets/images/GRTeamComingSoon.jpeg" class="w-100 roundedAll m-0 p-0"/> 
      
  </div>
</div>

<!-- Modal Klaim Poin -->
<div class="modal fade right" id="modalPoint" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
      <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
    <div class="modal-content">
      <div class="modal-body ">
          <form id="apply-form">
            <div class="form-group text-left">
                <label for="NoSpk">No SPK</label>
                <input type="text" class="form-control" id="NoSpk" name="NoSpk" v-model="formPoint.NoSpk" placeholder="SPK0001" />
            </div>
            <a class="btn btn-ksm btn-rounded btn-block text-white" @click="submitPoint">Klaim Poin</a>
          </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal Detail Kupon -->
<div class="modal fade right" id="modalKupon" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
      aria-hidden="true" data-backdrop="true">
    <div class="modal-dialog modal-dialog-centered modal-side modal-bottom-right modal-notify modal-danger" role="document">
        <div class="modal-content">
            <div class="modal-header m-0 p-0 w-100">
                <div id="background" >
                    <img :src="imgDetail" class="stretch" alt="" />
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" 
                    style="position: absolute; top:0; right: 0; margin-right: 5px;">
                    <span aria-hidden="true" >&times;</span>
                </button>
            </div>
            <div class="modal-body text-left w-100">
                <div class="row w-100 m-0 p-0 overflow-auto" style="max-height: 400px;">
                    <div class="col-12 w-100 my-1 mx-0 p-0" v-for="(kupon, index) in detailsKupon" :key="index">
                        <div class="card rounded shadow ">
                            <p :class="kupon.TglBSTB != undefined ? 'bg-detail-kupon-beli' : 'bg-detail-kupon-service'" 
                             class="mx-2 my-0 p-0 bg-detail-kupon-beli"><strong >{{ kupon.NamaUnit }}</strong></p>    
                            <p class="mx-2 my-0 p-0">Kupon Yang Di Dapat: <strong class="float-right">{{ kupon.xKali }} Kupon</strong></p>
                            <p class="mx-2 my-0 p-0" v-if="kupon.KetWarna != undefined ">Warna: <strong class="float-right">{{ kupon.KetWarna }}</strong></p>
                            <p class="mx-2 my-0 p-0">NoRangka: <strong class="float-right">{{ kupon.NoRangka }}</strong></p>
                            <p class="mx-2 my-0 p-0" v-if="kupon.Nopol != undefined ">Nopol: <strong class="float-right">{{ kupon.Nopol }}</strong></p>
                            <p class="mx-2 my-0 p-0" v-if="kupon.TglBSTB != undefined ">Tgl BSTB: <strong class="float-right">{{ kupon.TglBSTB }}</strong></p>
                            <p class="mx-2 my-0 p-0" v-if="kupon.TglCloseWO != undefined ">Tgl Close WO: <strong class="float-right">{{ kupon.TglCloseWO }}</strong></p>
                            <p class="mx-2 my-0 p-0" v-if="kupon.NamaSA != undefined ">Nama SA: <strong class="float-right">{{ kupon.NamaSA }}</strong></p>
                            <p class="mx-2 my-0 p-0" v-if="kupon.JenisServisBerkala != undefined ">Jenis Servis Berkala: <strong class="float-right">{{ kupon.JenisServisBerkala }}</strong></p>
                            <p class="mx-2 my-0 p-0">Total Bayar: <strong class="float-right">{{ this.convertRupiah(parseInt(kupon.TotalBayar)) }}</strong></p>
                        </div> 
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>

<div class="col-12" style="min-height: 100px;"> </div>

</template>
<script>
import $ from 'jquery';
import { nytMixin } from "../assets/js/myMixin.js";
export default {
    mixins: [nytMixin],
    setup() {
        
    },
    beforeMount(){
        if(localStorage.CalonKode)
        {
            this.namaCust = localStorage.Nama ;
            this.calonKodeCust = ' ( Kode Transaksi: ' + localStorage.CalonKode.replace("00001/C", "") + ' )';
            this.formPoint.CalonCust = localStorage.CalonKode;
        }
        this.getHistoryNotif();
        this.getPointCust();
        this.getKuponCust();
        
    },
    data(){
        return{
            jmlNotif: 0,
            myNotif: [],
            namaCust: '',
            calonKodeCust: '',
            formPoint: {
                NoSpk : '',
                CalonCust: '',
            },
            nominal: [],
            kupons: [],
            detailsKupon: [],
            detailXkali: '',
            imgDetail: ''
        }
    },
    methods: {
        async detailKupon(KodeCalon, Kode, KodeNota, Keterangan, xKali, Image)
        {
            this.detailXkali = xKali;
            this.imgDetail = Image;

            this.detailsKupon = [];

            var form_data = {KodeCalon: KodeCalon, Kode : Kode, KodeNota : KodeNota, Keterangan : Keterangan};
            var resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetDetailKupon", form_data);

            if(Keterangan == "Pembelian")
            {
                var getData = resultData.data;
                this.detailsKupon = getData.detail;
            }
            if(Keterangan == "Service")
            {
                var getData = resultData.data;
                this.detailsKupon = getData.detail;
            }
            $('#modalKupon').modal("show");
        },
        detailPoint()
        {
            const isAktif = this.nominal.filter(x => x.Status == 'Aktif');
            if(isAktif.length > 0 && isAktif[0].Nominal != '0')
            {
                //alert("Ada Yg Aktif");
            }
            else
            {
                alert("Belum Ada Poin Yang Aktif");
            }
        },
        submitPoint()
        {
            console.log(this.formPoint);
        },
        eventDetail()
        {
            $("#modalPoint").modal("show");

        },
        async getHistoryNotif()
        {
            var form_data = {KodeCust: localStorage.Kode, KodeCalon : localStorage.CalonKode};
            var resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetHistoryNotif", form_data);
            this.myNotif = resultData.data;
            if(this.myNotif.length > 0)
            {
                this.jmlNotif = this.myNotif.length;
            }
            else
            {
                this.jmlNotif = 0;
            }
        },
        async getPointCust()
        {
            var form_data = { KodeCalon : localStorage.CalonKode};
            var resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetPointCust", form_data);
            this.nominal = resultData.data;
        },
        async getKuponCust()
        {
            var form_data = { KodeCalon : localStorage.CalonKode};
            //var form_data = { KodeCalon : '00001/C003554'};
            var resultData = await this.FuncPost(this.urlSite + "/HttpApi/GetKupon", form_data);
            this.kupons = resultData.data;
        },
        async logout(){
            const form_data = {};
            form_data["CodeCust"] = localStorage.Kode; 
            const retData = await this.FuncPostSecond(this.urlSite + "/HttpApi/LogOut", form_data);
            
            const dataReturn = this.funcLogoutSecond();
            if(dataReturn)
            {
                window.location.href="/";
            }
            
        },
        eventMember()
        {
            $("#modalComingSoon").modal("show");
        }
    }
}
</script>

<style>
.fa-ellipsis-v{
	font-size: 10px;
	color: #C2C2C4;
	margin-top: 6px;
	cursor: pointer;
}
.text-dark{
	font-weight: bold;
	margin-top: 8px;
	font-size: 13px;
	letter-spacing: 0.5px;
}
.card-bottom{
	background: #3E454D;
	border-radius: 6px;
}
.flex-columnSetting{
	color: #adb5bd;
	font-size: 13px;
}
.flex-columnSetting p{
	letter-spacing: 1px;
	font-size: 18px;
}
.btn-secondarySetting{
	height: 35px!important;
	margin-top: 3px;
   
}
.btn-secondarySetting:focus{
	box-shadow: none !important;
}
</style>